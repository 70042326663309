import React, { ReactNode, useContext } from 'react';

import { ApiService } from '@ward/library';
import ChatBotService from 'services/ChatBotService';
import ChatService from '../../library/ChatService';
import RemoteConfigurationService from '../../services/RemoteConfigurationService';
import WhoAmIService from '../../library/WhoAmIService';
import loadServices from './loadServices';
import MatomoReactService from 'services/MatomoReactService';

export type ServiceList = {
  data: ApiService;
  remoteConfiguration: RemoteConfigurationService;
  whoami: WhoAmIService;
  matomo: MatomoReactService;
  chat: ChatService;
  chatBot: ChatBotService;
};

export const ServicesContext = React.createContext<ServiceList | null>(null);

function useAll() {
  const services = useContext(ServicesContext);
  if (!services) throw new Error('Services are not loaded');
  return services;
}

function Provider(props: { services: ServiceList; children: ReactNode }) {
  return (
    <ServicesContext.Provider value={props.services}>
      {props.children}
    </ServicesContext.Provider>
  );
}

const Services = {
  useAll,
  Provider,
  load: loadServices,
};

export default Services;
