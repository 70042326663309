import { Cast } from '@ward/library';
import WhoAmIService from 'library/WhoAmIService';
import { TrackEventsData, SmartSearchTypeName, createUid } from '@ward/library/dist/matomoUtils';
import Environment from 'utilities/Environment';

import { Me } from '../library/Me';

import { createInstance } from '@mitresthen/matomo-tracker-react'


export default class MatomoReactService {
  private readonly whoami: WhoAmIService;
  readonly url_api: string = Environment.matomo_url;
  readonly trackerUrl: string = Environment.matomo_url + 'matomo.php';
  readonly srcUrl: string = Environment.matomo_url + 'matomo.js';
  private matomo: any;

  constructor(whoami: WhoAmIService) {
    this.whoami = whoami;
    this.trackPageEvent = this.trackPageEvent.bind(this);
  }

  async init() {
    await this.setMatomo(this.whoami.getIdentity());
    console.log('MatomoReactService is initialized');
    return this;
  }

  private async setMatomo(student: Me | null) {
    this.matomo = createInstance({
      urlBase: this.url_api,
      siteId: 2,
      userId: createUid(Cast.toString(student?.id ?? '0'), student?.current_site ?? '(unknown)', student?.current_promo ?? '(unknown)'),
      trackerUrl: this.trackerUrl,
      srcUrl: this.srcUrl,
      disabled: false,
      configurations: {
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST',
      },
    });

    return this.matomo;
  }

  trackPage(uri: string, title: string) {
    return this.matomo.trackPageView({
      documentTitle: title,
      href: uri,
    });
  }

  trackPageEvent({ action, name, value, category, uri }: {action: string, name: string, value: number, category: string, uri: string }) {
    return this.matomo.trackEvent({
      action: action,
      name: name,
      value: value,
      category: category,
      href: uri,
    });
  }

}