import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: '',
};

export const activeThreadIdSlice = createSlice({
  name: 'activeThreadIdRedux',
  initialState,
  reducers: {
    setActiveThreadId: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setActiveThreadId } = activeThreadIdSlice.actions;

export const getActiveThreadId = (state: any) => {
  return state.activeThreadIdRedux.value;
};

export default activeThreadIdSlice.reducer;