import {
  ApiRequests,
  ApiService,
  Endpoints,
  RemoteConfiguration,
} from '@ward/library';

type RemoteServiceDeps = {
  api: ApiService;
};

const remoteConfigurationRequest = ApiRequests.fromEndpoint(
  Endpoints.remoteConfiguration.getRemoteConfiguration,
  {
    pathParams: null,
    queryParams: null,
    data: null,
    files: null,
  },
);

export default class RemoteConfigurationService {
  private api: ApiService;
  private configuration: RemoteConfiguration | null = null;

  constructor(deps: RemoteServiceDeps) {
    
    this.api = deps.api;
  }

  async init() {
    let configuration = null;
    for (let i = 0; i < 5 && !configuration; i++) {
      try {
        configuration = await this.fetch();
      } catch (err) {
        console.log(`Retry number ${i}`);
      }
    }
    if (!configuration) {
      console.log("Please report: 'backend remote-configuration error' to the dev team");
    }
    this.configuration = configuration;
    return this;
  }

  async fetch() {
    return this.api
      .fetch(remoteConfigurationRequest, {forceFetching: true})
      .catch(err => {
        throw err;
      });
  }

  getConfiguration() {
    const config = this.configuration;
    if (!config) throw new Error('No remote configuration available');
    return config;
  }
}
