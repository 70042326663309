import { Button, ButtonGroup, Modal as MuiModal, SxProps, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { FC } from 'react';

const buttonStyle: SxProps<Theme> =  {
    color: theme => theme.palette.secondary.contrastText,
};

export type ModalProps = {
    title: string;
    text: string;
    okText? : string
    cancelText? : string
    onClickOk: () => void;
    onClickCancel: () => void;
    toggleOpen: () => void;
    isOpen: boolean;
}

const Modal: FC<ModalProps> = ({ title, okText = "OK", cancelText= "Cancel", text, onClickOk, onClickCancel, toggleOpen, isOpen }) => {
    return (
        <MuiModal
            open={isOpen}
            onClose={toggleOpen}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            >
            <Box className='content'>
                <Typography id="modal-modal-title" variant="h3" component="h2">
                    {title}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {text}
                </Typography>
                <br/>
                <ButtonGroup variant="text" aria-label="text button group">
                    <Button onClick={onClickOk} sx={buttonStyle}>{okText}</Button>
                    <Button onClick={onClickCancel} sx={buttonStyle}>{cancelText}</Button>
                </ButtonGroup>
            </Box>
        </MuiModal>
    )
}

export default Modal