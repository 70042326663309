import { FC, useCallback, useEffect, useState } from 'react';

import { ChatMessageReceivedEvent, ChatThreadCreatedEvent, ChatThreadPropertiesUpdatedEvent } from '@azure/communication-chat';
import ChatModel from 'library/ChatModel';
import ChatNotifications from './ChatNotifications';
import Services from 'utilities/Services';

import { getReduxConversations, setReduxConversations } from 'components/pages/chat/conversationsSlice';
import { getActiveThreadId } from 'components/pages/chat/activeThreadIdSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ConversationModel } from 'library/ChatModel/ConversationModel';

//TODO: abonnement aux events de chat sur la création d'un thread par un autre utilisateur, sinon les notifs ne sont pas à jour tant qu'on n'a pas refresh la page

const ChatNotificationsContainer: FC = () => {
  const { chat } = Services.useAll();
  const [conversations, setConversations] = useState<ChatModel.Conversation[]>(chat.getConversations());
  const [nbUnreadMessages, setNbUnreadMessages] = useState<number>(0);

  const dispatch = useDispatch();
  const conversationsRedux = useSelector(getReduxConversations);
  const activeThreadIdRedux = useSelector(getActiveThreadId);

  const setUnreadConversations = useCallback(async (conversationsToCountUnread: ConversationModel[]) => {
    const total = chat.getTotalUnreadMessages(conversationsToCountUnread);
    setNbUnreadMessages(total);
  }, [chat]);

  const updateConversations = useCallback(async (e: ChatMessageReceivedEvent) => {
    // set a newConversations from the event array to update the state without fetching all the conversations again
    if (e.threadId) {
      const newConversations = chat.updateConversationsFromEvent(e, conversations, activeThreadIdRedux);
      dispatch(setReduxConversations(newConversations));
      setConversations(newConversations);
    }
  }, [chat, conversations, dispatch]);

  const updateConversationsProperties = useCallback(async (e: ChatThreadPropertiesUpdatedEvent) => {
    // set a newConversations from the event array to update the state without fetching all the conversations again
    if (!conversations) return;
    if (e.threadId) {
      const newConversations = chat.updateConversationsPropertiesFromEvent(e);
      dispatch(setReduxConversations(newConversations));
      setConversations(newConversations);
    }
  }, [dispatch]);

  const newConversationAdded = useCallback(async (e: ChatThreadCreatedEvent) => {
    // add the new conversation from the event array to update the state without fetching all the conversations again
    if (e.threadId) {
      const newConversations = chat.addedConversationFromEvent(e, conversations);
      dispatch(setReduxConversations(newConversations));
      setConversations(newConversations);
    }
  }, [chat, conversations, activeThreadIdRedux, dispatch]);

  // refresh the unread messages count when the conversations are updated
  useEffect(() => {
    if (!conversationsRedux) {
      setUnreadConversations(conversations);
      dispatch(setReduxConversations(conversations));
    } else {
      setUnreadConversations(conversationsRedux);
    }
  }, [conversations, conversationsRedux, setUnreadConversations, dispatch]);

  const markLastMessagesAsRead = useCallback(async () => {
    // compare activeThreadIdRedux with conversationsRedux to update the unread messages count
    if (activeThreadIdRedux) {
      if (!conversationsRedux) return;
      const newConversations = conversationsRedux.map((conversation: any) => {
        if (conversation.id === activeThreadIdRedux) {
          return { ...conversation, unread_messages_nb: 0 };
        }
        return conversation;
      }
      );
      dispatch(setReduxConversations(newConversations));
      setConversations(newConversations);
    }
  }, [activeThreadIdRedux, conversationsRedux, dispatch]);

  useEffect(() => {
    document.addEventListener('onChatMessageRead', markLastMessagesAsRead);

    return () => {
      document.removeEventListener('onChatMessageRead', markLastMessagesAsRead);
    }
  }, [updateConversations]);

  useEffect(() => {
    const chatClient = chat.getChatClient();
    chatClient.on('chatMessageReceived', updateConversations);
    chatClient.on('chatThreadCreated', newConversationAdded);
    chatClient.on('chatThreadPropertiesUpdated', updateConversationsProperties);

    return () => {
      chatClient.off('chatMessageReceived', updateConversations);
      chatClient.off('chatThreadCreated', newConversationAdded);
      chatClient.off('chatThreadPropertiesUpdated', updateConversationsProperties);
    };
  }, [chat, updateConversations, activeThreadIdRedux]);

  return <ChatNotifications nbUnreadMessages={nbUnreadMessages} />
};

export default ChatNotificationsContainer;
