import { alpha, SxProps, Theme } from '@mui/system';
import { Typography, Avatar, Stack } from '@mui/material';
import IconBackground from '../../assets/common/title-icon-bg.jpg';
import IconBackgroundGrey from '../../assets/common/title-icon-bg-grey.png';
import { Icon } from '../../core/utils';
import { Link } from 'react-router-dom';
import { FC } from 'react';
import themeVariable from '../theme';

const headerStyle: SxProps<Theme> = {
  alignItems: 'center',
  marginBottom: 4,
};

const titleStyle: SxProps<Theme> = {
    borderWidth: 0,
    borderLeft: 1,
    borderStyle: 'solid',
    borderColor: theme => alpha(theme.palette.primary.contrastText, 0.37),
    paddingLeft: 4,
    marginLeft: 4,
    small: {
      opacity: 0.31,
    },
};
  
const iconStyle: SxProps<Theme> = {
    height: 82,
    width: 82,
    backgroundImage: `url(${IconBackground})`,
    backgroundSize: 'contain',
    color: theme => theme.palette.primary.contrastText,
    a: {
      fontSize: 0,
    },
};
  
const iconGreyStyle: SxProps<Theme> = {
    backgroundImage: `url(${IconBackgroundGrey})`,
  };

type PageTitleProps = {
  title: string,
  subtitle?: string,
  icon?: string,
  iconId?: string,
  iconLink?: string | null,
  greyIcon?: boolean,
};

const PageTitle: FC<PageTitleProps> = ({ title, subtitle, icon, iconId, iconLink, greyIcon = false }) => {
  const renderIcon = () => {
    const iconColor = greyIcon
      ? themeVariable.palette.primary.main
      : themeVariable.palette.primary.contrastText;

    return iconId
      ? <Icon
          icon={iconId}
          size={35}
          color={iconColor}
        />
      : <img src={icon} alt="" />
  };

  return (
    <Stack direction="row" sx={headerStyle}>
      <Avatar sx={[iconStyle, greyIcon ? iconGreyStyle : {}]}>
        {iconLink
          ? (
            <Link to={iconLink}>
              {renderIcon()}
            </Link>
          ) : renderIcon()
        }
      </Avatar>
      <Typography variant="h1" sx={titleStyle}>
        {title}
        {subtitle &&
          <small> | {subtitle}</small>
        }
      </Typography>
    </Stack>
  );
};

export default PageTitle;
