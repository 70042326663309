import {ApiRequests, Endpoints} from '@ward/library';

// Menus

function getMenus() {
  return ApiRequests.fromEndpoint(Endpoints.menus.getMenus, {
    pathParams: null,
    queryParams: null,
    data: null,
    files: null,
  });
}

function getToolbox() {
  return ApiRequests.fromEndpoint(Endpoints.menus.getToolbox, {
    pathParams: null,
    queryParams: null,
    data: null,
    files: null,
  });
}

const requests = {
  getMenus,
  getToolbox,
};

export default requests;
