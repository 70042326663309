import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { SxProps, Theme, alpha } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';
import WardIcon from '../../../../core/utils/Icon';

import BgStudent from '../../../../assets/student/student-background.png';
import { Icon } from '../../../../core/utils';
import { Link } from 'react-router-dom';
import LoadingView from 'components/common/LoadingView';
import ModalReport from '../../../common/modal/ModalReport';
import { MicrosoftAuthenticationService, Model } from '@ward/library';
import authentication from 'core/api/authentication';
import { PageContainer } from '../../../common';
import { Routes } from '../../../../core/router';
import Services from 'utilities/Services';
import StudentAvatar from '../components/StudentAvatar';
import requests from '../requests';
import themeVariable from '../../../theme';

const containerStyle: SxProps<Theme> = {
  marginTop: -8,
};

const headerStyle: SxProps<Theme> = {
  position: 'relative',
  top: 84,
  alignItems: 'flex-start',
  display: 'flex',
  justifyContent: 'space-between',
};

const avatarContainerStyle: SxProps<Theme> = {
  marginLeft: 8,
  marginRight: 5,
  // display: 'flex',
  // justifyContent: 'space-between',
};

const studentnameStyle: SxProps<Theme> = {
  fontSize: 30,
  color: (theme) => theme.palette.primary.contrastText,
  textTransform: 'none',
  fontWeight: 300,
  lineHeight: 1,
  paddingRight: 4,
};

const gradeStyle: SxProps<Theme> = {
  fontWeight: 700,
  fontSize: 12,
  letterSpacing: '0.15em',
  color: (theme) => theme.palette.secondary.main,
  marginTop: 1,
};

const chatButtonStyle: SxProps<Theme> = {
  padding: '7px 42px',
  marginLeft: 4,
  borderRadius: '25px',
  fontSize: 10,
  fontWeight: 700,
  // background: (theme) => theme.palette.gradient.main,
  background: 'linear-gradient(160deg, #ab0926, #0046c9)',
  color: (theme) => theme.palette.primary.contrastText,
};

const contentStyle: SxProps<Theme> = {
  width: '100%',
  color: (theme) => theme.palette.secondary.contrastText,
  borderRadius: 5,
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 4,
  paddingBottom: 6,
  backgroundImage: `url(${BgStudent})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const asideStyle: SxProps<Theme> = {
  paddingRight: 5,
  paddingTop: 15,
};

const biographyStyle: SxProps<Theme> = {
  paddingRight: 6,
  fontSize: 16,
  fontWeight: 400,
  marginBottom: 5,
};

const listTitleStyle: SxProps<Theme> = {
  maxWidth: 447,
  marginY: 2,
  alignItems: 'center',
};

const listTitleTextStyle: SxProps<Theme> = {
  fontSize: 12,
  fontWeight: 300,
  marginRight: 2,
};

const listTitleLineStyle: SxProps<Theme> = {
  height: '1px',
  backgroundColor: (theme) => alpha(theme.palette.primary.contrastText, 0.26),
  flexGrow: 1,
};

const chipsStyle: SxProps<Theme> = {
  flexWrap: 'wrap',
};

const chipStyle: SxProps<Theme> = {
  background: 'transparent',
  border: '1px solid',
  borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.5),
  borderRadius: 1.5,
  fontWeight: 700,
  fontSize: 10,
  height: 23,
  marginRight: 1,
  marginBottom: 1,
  paddingTop: '1px',
};

const parcoursItemStyle: SxProps<Theme> = {
  position: 'relative',
  width: 145,
  height: 98,
  padding: 1.5,
  marginRight: 0.75,
  marginBottom: 0.75,
  borderRadius: 2,
  backgroundColor: (theme) => alpha(theme.palette.primary.dark, 0.4),
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  boxShadow: 'none',
};

const parcoursItemImageStyle: SxProps<Theme> = {
  height: 26,
  width: 26,
  marginBottom: 1,
};

const parcoursYearsStyle: SxProps<Theme> = {
  fontSize: 10,
  marginBottom: '3px',
  fontWeight: 400,
  lineHeight: 1.1,
};

const parcoursTitleStyle: SxProps<Theme> = {
  fontSize: 12,
  fontWeight: 700,
  marginBottom: '3px',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  lineHeight: 1.1,
};

const parcoursTitleOneLineStyle: SxProps<Theme> = {
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
};

const parcoursCategoryStyle: SxProps<Theme> = {
  fontSize: 8,
  fontWeight: 400,
  color: (theme) => theme.palette.secondary.main,
  textTransform: 'uppercase',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  lineHeight: 1.1,
};

const parcoursItemEditableStyle: SxProps<Theme> = {
  position: 'absolute',
  right: '8px',
  top: '8px',
  background: '#888',
  borderRadius: '50%',
  height: 16,
  width: 16,
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
};

const ParcoursCard = ({
  period,
  label,
  category,
  image,
  background,
  editable,
}: any) => (
  <Card
    sx={[
      parcoursItemStyle,
      background
        ? {
          backgroundImage: `linear-gradient(transparent, ${themeVariable.palette.primary.main}), url(${background})`,
        }
        : {},
    ]}
  >
    {image && <Avatar src={image} sx={parcoursItemImageStyle} />}
    <Typography sx={parcoursYearsStyle}>{period}</Typography>
    <Typography
      sx={[parcoursTitleStyle, image ? parcoursTitleOneLineStyle : {}]}
    >
      {label}
    </Typography>
    <Typography
      sx={[
        parcoursCategoryStyle,
        background ? { color: themeVariable.palette.primary.contrastText } : {},
      ]}
    >
      {category}
    </Typography>
    {editable && (
      <Stack sx={parcoursItemEditableStyle}>
        <Icon icon="feature_settings" size={12} />
      </Stack>
    )}
  </Card>
);

const logoutButtonStyle: SxProps<Theme> = {
  cursor: 'pointer',
  marginBottom: 5,
  display: 'flex',
  justifyContent: 'flex-end',
};

const logoutIconStyle: SxProps<Theme> = {
  marginRight: 1,
  alignContent: 'center',
  '&:hover': {
    color: theme => alpha(theme.palette.primary.contrastText, .6),
  },
};

const logoutStyle: SxProps<Theme> = {
  fontWeight: 500,
  fontSize: 9,
  letterSpacing: '0.15em',
  paddingTop: '2px',
  '&:hover': {
    color: theme => alpha(theme.palette.primary.contrastText, .6),
  },
};

const StudentDrawerProfil = ({ handleClose }: any) => {
  const { t } = useTranslation<Namespace>('profile');
  const { data, whoami } = Services.useAll();
  const me = whoami.useMe();
  const [student, setStudent] = useState<Model.Student>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const id = me.id;
  const isOwn = id === me.id;

  const getStudent = useCallback(
    async (studentId: number) => {
      setIsLoading(true);
      const response = await data.fetch(requests.getStudent(studentId));
      setIsLoading(false);
      if (!response) return;
      setStudent(response);
    },
    [data],
  );

  useEffect(() => {
    if (id && !isOwn) {
      getStudent(parseInt(id.toLocaleString()));
    } else {
      setStudent(me);
      setIsLoading(false);
    }
  }, [getStudent, id, isOwn, me]);

  const submitProfileReport = async (reason: string) => {
    if (id) {
      await data.fetch(requests.reportStudent(id, reason));
    }
  };

  const logout = async () => {
    localStorage.clear();
    if ((authentication as MicrosoftAuthenticationService).buildSingleLogOutWebPageUrl) {
      const dest = await (authentication as MicrosoftAuthenticationService).buildSingleLogOutWebPageUrl();
      window.location.href = dest;
    } else {
      window.location.href = "/";
    }
  };

  return (
    <PageContainer>
      {isLoading ? (
        <LoadingView />
      ) : (
        <Box sx={containerStyle}>
          {student && (
            <>
              <Stack direction="row" sx={headerStyle}>
                <Box sx={avatarContainerStyle}>
                    <StudentAvatar image={student.photo} big hideIcon={!isOwn} />
                </Box>
                  <Stack direction="row" alignItems="flex-start" marginTop={2} sx={{ width: '100%' }}>
                    <Box onClick={logout} sx={logoutButtonStyle}>
                      <Box sx={logoutIconStyle}>
                        <WardIcon icon='navigation_leave' size={15} />
                      </Box>
                      <Typography sx={logoutStyle}>
                        {t('logout')}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', paddingRight: 10 }}>
                    <Typography sx={studentnameStyle}>
                      {student.firstname} {student.lastname}
                      </Typography>
                    {(student.current_grade || student.current_site) && (
                      <Typography variant="h3" sx={gradeStyle}>
                        {student.current_grade}
                        {student.current_site ? ' - ' : ''}
                        {student.current_site}
                      </Typography>
                    )}
                  </Box>
                  {!isOwn && (
                    <Box >
                      <Link
                        to={{
                          pathname: Routes.Chat,
                          state: { userId: id }
                        }}
                      >
                        <Button
                          disableRipple
                          disableFocusRipple
                          sx={chatButtonStyle}
                        >
                          {t('start the conversation')}
                        </Button>
                      </Link>
                    </Box>
                  )}
                  </Stack>
                  <Stack direction="row" alignItems="flex-end" sx={{ marginTop: 2 }}>
                    <Button
                      onClick={handleClose}
                      sx={chatButtonStyle}>
                      <Icon icon="navigation_arrow-left" size={26} />
                    </Button>
                  </Stack>
              </Stack>
              <Grid container sx={contentStyle}>
                <Grid item xs={12} sm={3} sx={asideStyle}>
                  <Typography sx={biographyStyle}>
                    {student.biography}
                    </Typography>
                  {!isOwn && (
                    <ModalReport
                      contentType="profile"
                      submitReport={submitProfileReport}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={3} sx={asideStyle} >
                  {
                    student.hobbies?.length > 0 && (
                      <>
                        <Stack direction="row" sx={listTitleStyle}>
                          <Typography sx={listTitleTextStyle}>
                            {t('hobbies')}
                          </Typography>
                          <Box sx={listTitleLineStyle} />
                        </Stack>
                        <Stack direction="row" sx={chipsStyle}>
                          {student.hobbies.map(hobby => (
                            <Chip
                              key={hobby.id}
                              sx={chipStyle}
                              label={hobby.label}
                            />
                          ))}
                        </Stack>
                      </>
                    )
                  }
                  {student.assistances?.length > 0 && (
                    <>
                      <Stack direction="row" sx={listTitleStyle}>
                        <Typography sx={listTitleTextStyle}>
                          {t('assistances')}
                        </Typography>
                        <Box sx={listTitleLineStyle} />
                      </Stack>
                      <Stack direction="row" sx={chipsStyle}>
                        {student.assistances.map(assistance => (
                          <Chip
                            key={assistance.id}
                            sx={chipStyle}
                            label={assistance.label}
                          />
                        ))}
                      </Stack>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {student.grades?.length > 0 && (
                    <>
                      <Stack direction="row" sx={listTitleStyle}>
                        <Typography sx={listTitleTextStyle}>
                          {t('academic background')}
                        </Typography>
                        <Box sx={listTitleLineStyle} />
                      </Stack>
                      <Stack direction="row" flexWrap="wrap">
                        {student.grades.map(grade => (
                          <ParcoursCard
                            key={grade.id}
                            period={grade.years}
                            label={grade.grade}
                            category={grade.school}
                          />
                        ))}
                      </Stack>
                    </>
                  )}
                  {student.jobs?.length > 0 && (
                    <>
                      <Stack direction="row" sx={listTitleStyle}>
                        <Typography sx={listTitleTextStyle}>
                          {t('career')}
                        </Typography>
                        <Box sx={listTitleLineStyle} />
                      </Stack>
                      <Stack direction="row" flexWrap="wrap">
                        {student.jobs.map(job => (
                          <ParcoursCard
                            key={job.id}
                            period={job.years}
                            label={job.company}
                            category={job.contract}
                            image={null}
                            editable={false}
                          />
                        ))}
                        {
                          // disabled (v2)
                          /*isOwn &&
                            <Link to={Routes.StudentAddJob}>
                              <Button sx={[styles.parcoursItem, styles.parcoursItemButton]}>
                                +
                              </Button>
                            </Link>
                          */
                        }
                      </Stack>
                    </>
                  )}
                  {student.trips?.length > 0 && (
                    <>
                      <Stack direction="row" sx={listTitleStyle}>
                        <Typography sx={listTitleTextStyle}>
                          {t('international career')}
                        </Typography>
                        <Box sx={listTitleLineStyle} />
                      </Stack>
                      <Stack direction="row" flexWrap="wrap">
                        {student.trips.map(trip => (
                          <ParcoursCard
                            key={trip.id}
                            period={trip.years}
                            label={trip.city}
                            category={trip.country}
                            image={trip.flag}
                            background={trip.photo}
                          />
                        ))}
                      </Stack>
                    </>
                  )}
                  {student.projects?.length > 0 && (
                    <>
                      <Stack direction="row" sx={listTitleStyle}>
                        <Typography sx={listTitleTextStyle}>
                          {t('project career')}
                        </Typography>
                        <Box sx={listTitleLineStyle} />
                      </Stack>
                      <Stack direction="row" flexWrap="wrap">
                        {student.projects.map(project => (
                          <ParcoursCard
                            key={project.id}
                            period={project.years}
                            label={project.name}
                            category={project.category}
                            image={project.logo}
                          />
                        ))}
                      </Stack>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      )}
    </PageContainer>
  );
};

export default StudentDrawerProfil;
