import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  value: false,
};

export const isToolboxSlice = createSlice({
  name: 'isToolbox',
  initialState,
  reducers: {
    setIsToolbox: (state: any, action: any) => {
      state.value = action.payload;
    },
  },
});

export const { setIsToolbox }: any = isToolboxSlice.actions;

export const getIsToolbox = (state: any) => state.isToolbox.value;

export default isToolboxSlice.reducer;
