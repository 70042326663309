import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  value: {
    session: null,
    messages: [],
    responding: false,
  },
};

export const chatBotSessionSlice = createSlice({
  name: 'chatBotSession',
  initialState,
  reducers: {
    setChatBotSession: (state: any, action: any) => {
      state.value.session = action.payload;
    },
    setChatBotMessages: (state: any, action: any) => {
      state.value.messages = action.payload;
    },
    setChatBotResponding: (state: any, action: any) => {
      state.value.responding = action.payload;
    },
  },
});

export const { setChatBotSession, setChatBotMessages, setChatBotResponding }: any = chatBotSessionSlice.actions;

export const getChatBotSession = (state: any) => state.chatBotSession.value;

export default chatBotSessionSlice.reducer;
