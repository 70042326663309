import { Experiences } from "./menus/experiences";
import { ToolBox } from "./menus/toolbox";
import { getIsExperiences } from "./menus/experiences/isExperiencesSlice";
import { getIsToolbox } from "./menus/toolbox/isToolboxSlice";
import { useSelector } from "react-redux";

const AppCommonComponent = () => {
    const isToolbox = useSelector(getIsToolbox);
    const isExperiences = useSelector(getIsExperiences);
    return (
        <>
            {isToolbox && (
                <ToolBox />
            )}
            {isExperiences && (
                <Experiences />
            )}
        </>
    )
}
export default AppCommonComponent;