import {
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Model, Reference } from '@ward/library';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { SxProps, Theme, alpha } from '@mui/system';
import { useCallback, useEffect, useRef, useState } from 'react';

import LoadingView from 'components/common/LoadingView';
import { Redirect } from 'react-router-dom';
import { Icon } from '../../../../core/utils';
import { Routes } from '../../../../core/router';
import Services from 'utilities/Services';
import StudentAvatar from '../components/StudentAvatar';
import requests from '../requests';
import StudentCategoryList from '../components/StudentCategoryList';

const contentStyle: SxProps<Theme> = {
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 1,
  paddingBottom: 6,
};

const avatarContainerStyle: SxProps<Theme> = {
  marginRight: 2,
  marginBottom: 5,
};

const leftContentStyle: SxProps<Theme> = {
  width: '100%',
};

const biographyFieldStyle: SxProps<Theme> = {
  fontSize: 14,
  fontWeight: 300,
  '& .MuiInputBase-root': {
    padding: 2,
  },
  '& .MuiInputBase-input': {
    color: (theme) => `${theme.palette.primary.main} !important`,
  },
  '& fieldset': {
    borderRadius: 5,
  },
};

const submitButtonStyle: SxProps<Theme> = {
  marginTop: 3,
  width: 330,
};

const titleStyle: SxProps<Theme> = {
  color: (theme) => theme.palette.primary.main,
  fontSize: 16,
  fontWeight: 500,
  marginBottom: 3,
  paddingY: 1 / 2,
  '&:not(:last-child)': {
    marginRight: 3,
    paddingRight: 3,
    borderRightWidth: '1px',
    borderRightStyle: 'solid',
    borderRightColor: (theme) => alpha(theme.palette.primary.main, 0.3),
  },
};

const titleDisabledStyle: SxProps<Theme> = {
  color: (theme) => alpha(theme.palette.primary.main, 0.25),
  cursor: 'pointer',
  '&:hover': {
    color: (theme) => alpha(theme.palette.primary.main, 0.4),
  },
};

const chipStyle: SxProps<Theme> = {
  display: 'flex',
  paddingLeft: 2,
  paddingRight: 1,
  justifyContent: 'left',
  color: '#24358E',
  borderColor: '#24358E',
  borderRadius: 1.5,
  fontWeight: 600,
  fontSize: 12,
  height: 37,
  marginRight: 1,
  marginBottom: 1 / 2,
  '& .MuiChip-label': {
    width: '100%',
  },
  '& .MuiChip-deleteIcon': {
    color: (theme) => alpha(theme.palette.primary.main, 0.3),
    '&:hover': {
      color: (theme) => alpha(theme.palette.primary.main, 0.2),
    },
  },
};

const searchFieldStyle: SxProps<Theme> = {
  marginBottom: 1,
  '& .MuiInput-input': {
    color: '#24358E',
    fontSize: 12,
    fontWeight: 600,
  },
  '& .MuiInput-underline': {
    '&::before': {
      borderColor: (theme) => alpha(theme.palette.primary.main, 0.3),
    },
    '&:hover::before': {
      borderWidth: 1,
      borderColor: (theme) => alpha(theme.palette.primary.main, 0.5),
    },
  },
};

const searchIconStyle: SxProps<Theme> = {
  color: '#24358E',
};

const containerStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.secondary.contrastText,
  color: theme.palette.primary.main,
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20,
  marginTop: 3,
  maxWidth: theme.breakpoints.values.md,
  [theme.breakpoints.up('xl')]: {
    // marginLeft: `calc(((100vw - ${theme.breakpoints.values.lg}px) / -2) - 32px)`,
    maxWidth: `calc(((100vw - ${theme.breakpoints.values.lg}px) / 2) + ${theme.breakpoints.values.md}px)`,
  },
});

const returnButtonStyle: SxProps<Theme> = {
  padding: '7px 42px',
  marginLeft: 4,
  borderRadius: '25px',
  background: 'linear-gradient(160deg, #ab0926, #0046c9)',
  color: (theme) => theme.palette.primary.contrastText,
};

const topBarStyle: SxProps<Theme> = (theme) => ({
  height: 50,
  background: alpha(theme.palette.primary.main, 0.25),
  paddingRight: 6,
  paddingBottom: .7,
  [theme.breakpoints.up('xl')]: {
    paddingLeft: `calc(((100vw - ${theme.breakpoints.values.lg}px) / 2) + 48px)`,
  },
});

type CategoryItem = {
  item: Reference.Hobby;
  categoryLabel: string;
};

const StudentDrawerSettingsContent = ({ handleClose }: any) => {
  const { t } = useTranslation<Namespace>('profile');
  const { data, whoami } = Services.useAll();
  const me = whoami.useMe();
  const inputFile: any = useRef(null);
  const [search, setSearch] = useState<string>('');
  const [selectedList, setSelectedList] = useState<number>(0);
  const [hobbies, setHobbies] = useState<Model.Hobby[]>([]);
  const [displayedHobbies, setDisplayedHobbies] = useState<Model.Hobby[]>([]);
  const [assistances, setAssistances] = useState<Model.Assistance[]>([]);
  const [displayedAssistances, setDisplayedAssistances] = useState<
    Model.Assistance[]
  >([]);
  const [biography, setBiography] = useState<string>(me.biography);
  const [newImage, setNewImage] = useState<Blob | null>(null);
  const [isRedirect, setIsRedirect] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCategoriesLoading, setIsCategoriesLoading] =
    useState<boolean>(false);
  const [isAddingCategory, setIsAddingCategory] = useState<boolean>(false);
  const [addItem, setAddItem] = useState<CategoryItem>({
    item: {} as Reference.Hobby,
    categoryLabel: '',
  });

  useEffect(() => {
    if (addItem.categoryLabel === 'hobbies') {
      addStudentHobby(addItem.item);
    }
    if (addItem.categoryLabel === 'assistances') {
      addStudentAssistance(addItem.item);
    }
  }, [addItem]);

  const toggleLoading = (loadingState: boolean) => {
    setIsLoading(loadingState);
  };

  const toggleCategoriesLoading = (loadingState: boolean) => {
    setIsCategoriesLoading(loadingState);
  };

  const toggleAddCatory = (loadingState: boolean) => {
    setIsAddingCategory(loadingState);
  };

  const fetchHobbies = useCallback(async () => {
    toggleCategoriesLoading(true);
    const response = await data.fetch(requests.getHobbies());
    if (!response) return;
    setHobbies(response);
    toggleCategoriesLoading(false);
  }, [data]);

  const fetchAssistances = useCallback(async () => {
    toggleCategoriesLoading(true);
    const response = await data.fetch(requests.getAssistances());
    if (!response) return;
    setAssistances(response);
    toggleCategoriesLoading(false);
  }, [data]);

  useEffect(() => {
    fetchHobbies();
    fetchAssistances();
  }, []);

  useEffect(() => {
    if (search.length < 3) {
      setDisplayedHobbies(hobbies);
      setDisplayedAssistances(assistances);
    }
    filterCategories(search, selectedList === 1 ? assistances : hobbies);
  }, [search, hobbies, assistances]);

  const filterCategories = (value: string, categoryList: any) => {
    setSearch(value);
    const searchValue = value.length > 2 ? value : '';
    // const searchValue = debouncedSearch;
    const filteredCategory = categoryList.filter(
      (categoryItem: { label: string }) =>
        categoryItem.label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            searchValue
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
          ),
    );
    if (selectedList === 0) {
      setDisplayedHobbies(filteredCategory);
    }
    if (selectedList === 1) {
      setDisplayedAssistances(filteredCategory);
    }
  };

  const updateStudent = async (context: string) => {
    const newMe = await whoami.fetchMe(true);
    whoami.setMe(newMe);

    if (context === 'biography') {
      toggleLoading(false);
    } else {
      toggleCategoriesLoading(false);
      toggleAddCatory(false);
    }
  };

  const addStudentHobby = async (item: Reference.Hobby) => {
    //toggleCategoriesLoading(true);
    toggleAddCatory(true);
    await data.fetch(requests.createStudentHobby(item.id));
    updateStudent('hobbies');
  };

  const addStudentAssistance = async (item: Reference.Assistance) => {
    toggleAddCatory(true);
    await data.fetch(requests.createStudentAssistance(item.id));
    updateStudent('assistances');
  };

  const deleteStudentHobby = async (id: number) => {
    toggleAddCatory(true);
    await data.fetch(requests.removeStudentHobby(id));
    updateStudent('hobbies');
  };

  const deleteStudentAssistance = async (id: number) => {
    toggleAddCatory(true);
    await data.fetch(requests.removeStudentAssistance(id));
    updateStudent('assistances');
  };

  const handleDeleteHobby = (id: number) => {
    deleteStudentHobby(id);
  };

  const handleDeleteAssistance = (id: number) => {
    deleteStudentAssistance(id);
  };

  const updateStudentImage = async () => {
    await data.fetch(requests.updateStudentPhoto(newImage));
  };

  const handleSubmit = async () => {
    toggleLoading(true);
    await data.fetch(requests.editStudent({ biography }));
    if (newImage) {
      await updateStudentImage();
    }
    updateStudent('biography');
    setIsRedirect(true);
  };

  const showUploadImage = () => {
    inputFile?.current.click();
  };

  if (!me?.id) {
    return null;
  }

  if (isRedirect) {
    return <Redirect to={Routes.Student} />;
  }

  //TODO: crop the biography text if it's too long

  return (
    <Box sx={containerStyle}>
      <Box >
        <Stack alignItems="flex-end" justifyContent="flex-end" sx={topBarStyle}>
          <Button
            onClick={handleClose}
            sx={returnButtonStyle}>
            <Icon icon="navigation_arrow-left" size={26} />
          </Button>
        </Stack>
        {isLoading ? (
          <Box style={{ height: '70vh', padding: '200px' }}>
            <LoadingView color="inherit" />
          </Box>
        ) : (
          <Grid container sx={contentStyle} spacing={8}>
            <Grid item md={4} >
              <Stack>
                <Stack direction="row" justifyContent="flex-start">
                  <Box sx={avatarContainerStyle} onClick={showUploadImage}>
                    <StudentAvatar
                      image={newImage ? URL.createObjectURL(newImage) : me.photo}
                      icon="navigation_plus-image"
                      big
                    />
                    <input
                      type="file"
                      hidden
                      ref={inputFile}
                      name="image"
                      onChange={(event: any) =>
                        setNewImage(event.target.files[0])
                      }
                    />
                  </Box>
                  <Typography fontSize={22} fontWeight={300} marginTop={7}>
                    {me.firstname}
                    <br />
                    <Typography noWrap fontSize={20} fontWeight={300} width={200}>
                      {me.lastname}
                    </Typography>
                  </Typography>
                  </Stack>

                <Box sx={leftContentStyle}>
                  <TextField
                    sx={biographyFieldStyle}
                    label={t('biography')}
                    value={biography}
                    onChange={(event: any) => setBiography(event.target.value)}
                    rows={10}
                    multiline
                    fullWidth
                  />
                  <Button
                    sx={submitButtonStyle}
                    disableRipple
                    disableFocusRipple
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    fullWidth
                  >
                    {t('apply')}
                  </Button>
                </Box>
              </Stack>
            </Grid>
            <Grid item md={4} >
              <Stack direction="row">
                <Typography
                  sx={[
                    titleStyle,
                    selectedList !== 0 ? titleDisabledStyle : {},
                  ]}
                  onClick={() => {
                    setSelectedList(0);
                    if (search.length > 0) setSearch('');
                  }}
                >
                  {t('hobbies')}
                </Typography>
                <Typography
                  sx={[
                    titleStyle,
                    selectedList !== 1 ? titleDisabledStyle : {},
                  ]}
                  onClick={() => {
                    setSelectedList(1);
                    if (search.length > 0) setSearch('');
                  }}
                >
                  {t('assistances')}
                </Typography>
              </Stack>

              {isAddingCategory && selectedList === 0 ? (
                <Box textAlign="center">
                  <LoadingView color="inherit" />
                </Box>
              ) : (
                selectedList === 0 && (
                  <Stack direction="column">
                    {me.hobbies.map((hobby: Reference.Hobby) => (
                      <Chip
                        key={hobby.id}
                        sx={chipStyle}
                        variant="outlined"
                        label={hobby.label}
                        onDelete={() => handleDeleteHobby(hobby.id)}
                      />
                    ))}
                  </Stack>
                )
              )}

              {isAddingCategory && selectedList === 1 ? (
                <Box textAlign="center">
                  <LoadingView color="inherit" />
                </Box>
              ) : (
                selectedList === 1 && (
                  <Stack direction="column">
                    {me.assistances.map((assistance: Reference.Assistance) => (
                      <Chip
                        key={assistance.id}
                        sx={chipStyle}
                        variant="outlined"
                        label={assistance.label}
                        onDelete={() => handleDeleteAssistance(assistance.id)}
                      />
                    ))}
                  </Stack>
                )
              )}
            </Grid>
            <Grid item md={4} >
              <Box style={{ maxHeight: '60vh', overflow: 'auto' }}>
                <TextField
                  sx={searchFieldStyle}
                  variant="standard"
                  fullWidth
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={searchIconStyle}>
                        <Icon icon="navigation_magnify" size={33} />
                      </InputAdornment>
                    ),
                  }}
                />
                {isCategoriesLoading && selectedList === 0 ? (
                  <Box textAlign="center">
                    <LoadingView color="inherit" />
                  </Box>
                ) : (
                  selectedList === 0 && (
                    <StudentCategoryList
                      categoryLabel="hobbies"
                      category={displayedHobbies}
                      updateItem={setAddItem}
                    />
                  )
                )}

                {isCategoriesLoading && selectedList === 1 ? (
                  <Box textAlign="center">
                    <LoadingView color="inherit" />
                  </Box>
                ) : (
                  selectedList === 1 && (
                    <StudentCategoryList
                      categoryLabel="assistances"
                      category={displayedAssistances}
                      updateItem={setAddItem}
                    />
                  )
                )}
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default StudentDrawerSettingsContent;
