import { Button, Step, StepIcon, Stepper } from '@mui/material';
import { SxProps, Theme, alpha } from '@mui/system';

import { FC } from 'react';

const stepperStyle: SxProps<Theme> = {
  marginBottom: 3,
  '& .MuiStep-horizontal': {
    '&:first-child': {
      paddingLeft: 0,
    }
  },
  '& .MuiStepConnector-root': {
    maxWidth: 40,
  },
};

const stepStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 26,
  height: 26,
  minWidth: 'unset',
  fontSize: 10,
  fontWeight: 600,
  lineHeight: 1,
  borderRadius: '50%',
  border: '1px solid',
  borderColor: theme => alpha(theme.palette.primary.contrastText, .5),
  backgroundColor: theme => theme.palette.primary.contrastText,
  color: '#24358E',
  '&:hover': {
    backgroundColor: theme => theme.palette.primary.contrastText,
  }
};

const stepCompletedStyle: SxProps<Theme> = {
  backgroundColor: 'transparent',
  color: theme => theme.palette.primary.contrastText,
  '&:hover': {
    backgroundColor: theme => alpha(theme.palette.primary.contrastText, .1),
  }
};

type SearchStepperProps = {
  currentStep: number,
  onClickStep: Function,
};

const STEPS = [1, 2, 3];

const SearchStepper: FC<SearchStepperProps> = ({ currentStep, onClickStep }) => {
  const renderStep = (label: number, index: number) => {
    if(index > currentStep) return;
    return (
      <Step key={label} completed={currentStep > index}>
        <StepIcon
          icon={
            <Button
              onClick={() => onClickStep(index)}
              sx={[stepStyle, currentStep > index ? stepCompletedStyle : {}]}
            >
              {label}
            </Button>
          }
        />
      </Step>
    );
  }

  return (
    <Stepper sx={stepperStyle} activeStep={0}>
      {STEPS.map((label: number, index: number) =>
        renderStep(label, index)
      )}
    </Stepper>
  );
};

export default SearchStepper;