import IcoMoon from 'react-icomoon';
import iconSet from '@ward/library/assets/icons/WardIcons/selection.json';

interface IconProps {
  [x: string]: any;
  icon: string;
}

const Icon = (props: IconProps) => {
  return (
    <IcoMoon iconSet={iconSet} {...props} />
  );
};

export default Icon;
