import '../core/utils/main.css';

import Services, {ServiceList} from '../utilities/Services';
import React, { useCallback, useEffect, useState, Suspense } from 'react';

import { AsyncState } from '@ward/library';
import Body from './Body';
import CssBaseline from '@mui/material/CssBaseline';
import { Login } from '../components/pages/login';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { store } from '../app/store';
import theme from './theme';
import useLoggedIn from './pages/login/useLoggedIn';

const MobileApp = React.lazy(() => import('./MobileApp'))
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

interface IWindowSize {
  width: number;
  height: number;
}

const App = () => {
  const [windowDimensions] = useState<IWindowSize>(getWindowDimensions());
  const { isLoading, isLoggedIn } = useLoggedIn();
  const [servicesState, startServicesLoading] =
    AsyncState.useState<ServiceList>();

  const buildServices = useCallback(() => {
    const promise = Services.load();
    startServicesLoading().listen(promise);
  }, [startServicesLoading]);

  useEffect(() => {
    buildServices();

  }, [buildServices]);

  if (!AsyncState.isResolved(servicesState)) {
    return null;
  }

  return (
    <>
      {windowDimensions && windowDimensions.width >= 700 ? (
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <Services.Provider services={servicesState.value}>
                <CssBaseline />
                {isLoggedIn &&
                  <Body />
                }
                {!isLoading && !isLoggedIn &&
                  <Login />
                }
              </Services.Provider>
            </ThemeProvider>
        </Provider>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <MobileApp />
        </Suspense>
      )}
    </>
  );
};

export default App;
