import { Avatar, Box, Button, Drawer } from '@mui/material';
import type { SxProps, Theme } from '@mui/system';

import { FC, useState } from 'react';
import { Icon } from '../../../../core/utils';
import { lighten } from "@mui/material/styles";

import StudentDrawerProfil from './StudentDrawerProfil';
import StudentDrawerSettingsContent from './StudentDrawerSettingsContent';

const containerStyle: SxProps<Theme> = {
    position: 'relative',
    height: 61,
  width: 61,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: (theme) => theme.palette.primary.light,
    borderRadius: '100%',
};

const containerBigStyle: SxProps<Theme> = {
    height: 169,
    width: 169,
  paddingTop: .6,
  paddingLeft: .6,
};

const avatarStyle: SxProps<Theme> = {
    height: 53,
    width: 53,
};

const avatarBigStyle: SxProps<Theme> = {
    height: 151,
    width: 151,
};

const settingStyle: SxProps<Theme> = {
    position: 'absolute',
    top: 'calc(16% - 9px)',
    right: 'calc(16% - 9px)',
    padding: 0,
    background: (theme) => theme.palette.primary.main,
    borderRadius: ' 50%',
    display: 'flex',
    border: '1px solid #7A80A7',
    alignItems: 'center',
    justifyContent: 'center',
    width: 18,
    height: 18,
    minWidth: 'auto',
    zIndex: 1,
    color: (theme) => theme.palette.primary.contrastText,
    '&:hover': {
        background: (theme) => lighten(theme.palette.primary.main, 0.1),
    },
};

const drawerSettingsStyle: SxProps<Theme> = {
  '& .css-1eqkfw5-MuiBackdrop-root-MuiModal-backdrop': {
    backgroundColor: 'transparent',
    marginTop: 0,
  },
};

const settingBigStyle: SxProps<Theme> = {
    top: 'calc(15% - 26px)',
    right: 'calc(15% - 26px)',
    width: 47,
    height: 47,
};

type StudentAvatarProps = {
  image?: string | null,
  link?: any,
  icon?: string,
  iconLink?: any,
  iconBgColor?: string,
  big?: boolean,
  hideIcon?: boolean,
};

const StudentAvatar: FC<StudentAvatarProps> = ({ image, link, icon, iconLink, iconBgColor, big, hideIcon }) => {
  const [drawerProfilOpen, setDrawerProfilOpen] = useState(false);
  const [drawerSettingsOpen, setDrawerSettingsOpen] = useState(false);

  const handleDrawerProfilOpen = () => {
    setDrawerProfilOpen(true);
  }

  const handleDrawerProfilClose = () => {
    setDrawerProfilOpen(false);
  }

  const handleDrawerSettingsOpen = () => {
    if (!big) setDrawerProfilOpen(true);
    setDrawerSettingsOpen(true);
  };

  const handleDrawerSettingsClose = () => {
    setDrawerSettingsOpen(false);
  };

  const renderAvatar = () =>
    image
      ? <Avatar sx={[avatarStyle, big ? avatarBigStyle : {}]} src={image} />
      : null;

  const iconBgColorStyles = iconBgColor
    ? {
      backgroundColor: iconBgColor,
      '&:hover': {
        backgroundColor: lighten(iconBgColor, .2),
      },
    }
    : {};

  return (
    <Box sx={[containerStyle, big ? containerBigStyle : {}]} >
      {!hideIcon && (
        <Button
          sx={[settingStyle, big ? settingBigStyle : {}, iconBgColorStyles]}
          onClick={handleDrawerSettingsOpen}
        >
          <Icon icon={icon || 'feature_settings'} size={big ? 26 : 10} />
        </Button>
      )}
      <Button sx={{ paddingTop: .4, paddingLeft: .4 }} onClick={handleDrawerProfilOpen}>
        {renderAvatar()}
      </Button>
      <Drawer sx={{ paddingTop: 0 }} anchor="left" open={drawerProfilOpen} onClose={handleDrawerProfilClose}>
        <Box sx={{ width: '100%', padding: 0 }}>
          <StudentDrawerProfil handleClose={handleDrawerProfilClose} />
        </Box>
      </Drawer>
      <Drawer sx={drawerSettingsStyle} anchor="left" open={drawerSettingsOpen} onClose={handleDrawerSettingsClose}>
        <Box sx={{ width: '100%', padding: 0 }}>
          <StudentDrawerSettingsContent handleClose={handleDrawerSettingsClose} />
        </Box>
      </Drawer>
    </Box>
  );
};

export default StudentAvatar;
