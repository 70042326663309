import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    next_event: {},
  },
};

export const homepageSlice = createSlice({
  name: 'homepageData',
  initialState,
  reducers: {
    setHomepageNextEvent: (state: any, action: any) => {
      state.value = {
        ...state.value,
        next_event: action.payload,
      };
    },
    deleteHomepageNextEvent: (state: any) => {
      state.value = {
        ...state.value,
        next_event: null,
      };
    },
  },
});

export const { setHomepageNextEvent, deleteHomepageNextEvent }: any =
  homepageSlice.actions;

export const getHomepageData = (state: any) => state.homepageData.value;

export default homepageSlice.reducer;
