import { enGB, enUS, fr } from 'date-fns/locale';

import { format } from 'date-fns';

export const getPreferredLanguage = (): string => {
  // const { data, whoami } = Services.useAll();
  // const me = whoami.fetchMe();
  // console.log(me);
  return navigator.languages ? navigator.languages[0] : 'fr-FR';
};

export const getLocale = () => {
  const language = getPreferredLanguage();
  switch(language){
    case 'en-GB':
      return enGB;
    case 'en-US':
      return enUS;
    default:
      return fr;
  }
}

export const formatLocale = (date: Date, form: string) =>
  format(date, form, { locale: getLocale() });
