// Language selection

import { ApiRequests, Endpoints } from '@ward/library';

function editStudent(data?: {
  biography?: string;
  onboarded?: boolean;
  idLanguage?: number;
  language?: string;
  preferredLanguage?: string;
}) {
  return ApiRequests.fromEndpoint(Endpoints.students.updateStudent, {
    pathParams: { id: 'current' },
    queryParams: null,
    data: data || {},
    files: null,
  });
}

function getLanguages() {
  return ApiRequests.fromEndpoint(Endpoints.languages.getLanguages, {
    pathParams: null,
    queryParams: null,
    data: null,
    files: null,
  });
}

const requests = {
  editStudent,
  getLanguages,
};

export default requests;
