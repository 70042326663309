import {
  Box,
  Stack,
  Typography,
  alpha,
} from '@mui/material';
import { SxProps, Theme } from '@mui/system';

import { FC } from 'react';
import { Icon } from '../../core/utils';
import { Link } from 'react-router-dom';

const containerStyle: SxProps<Theme> = theme => ({
  backgroundColor: theme.palette.secondary.contrastText,
  color: theme.palette.primary.main,
  borderTopRightRadius: 20,
  borderBottomRightRadius: 20,
  marginBottom: 20,
  marginLeft: -4,
  maxWidth: theme.breakpoints.values.md,
  [theme.breakpoints.up('xl')]: {
    marginLeft: `calc(((100vw - ${theme.breakpoints.values.lg}px) / -2) - 32px)`,
    maxWidth: `calc(((100vw - ${theme.breakpoints.values.lg}px) / 2) + ${theme.breakpoints.values.md}px)`,
  },
});

const topBarStyle: SxProps<Theme> = theme => ({
  height: 52,
  background: alpha(theme.palette.primary.main, .25),
  paddingLeft: 6,
  '& a': {
    display: 'inline-block',
    height: '100%',
  },
  [theme.breakpoints.up('xl')]: {
    paddingLeft: `calc(((100vw - ${theme.breakpoints.values.lg}px) / 2) + 48px)`,
  },
});

const backStyle: SxProps<Theme> = {
  flexGrow: 0,
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '100%',
  columnGap: 6,
};

const backIconStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
};

const contentStyle: SxProps<Theme> = theme => ({
  [theme.breakpoints.up('xl')]: {
    paddingLeft: `calc((100vw - ${theme.breakpoints.values.lg}px) / 2)`,
  },
});

interface FormContainerProps {
  backLabel: string,
  backLink: any,
  children: any,
}

const FormContainer: FC<FormContainerProps> = ({ backLabel, backLink, children }) =>
  <Box sx={containerStyle}>
    <Box sx={topBarStyle}>
      <Link to={backLink}>
        <Stack direction='row' sx={backStyle}>
          <Stack sx={backIconStyle} alignItems='center'>
            <Icon icon='navigation_arrow-left' size={26} />
          </Stack>
          <Typography variant="h3">{backLabel}</Typography>
        </Stack>
      </Link>
    </Box>
    <Box sx={contentStyle}>
      {children}
    </Box>
  </Box>

export default FormContainer;
