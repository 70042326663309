import { AnyError } from '@ward/library';
import { ChatbotConfig } from './ChatBotService';
import Environment from '../utilities/Environment';
import { t } from 'i18next';

export const chatBotConfig: Omit<ChatbotConfig, 'apiService'> = {
  scriptConfig: {
    url: Environment.chatbot_url,
    name: Environment.chatbot_api_script_name,
  },
  messages: {
    welcome: () => t('chat:welcome'),
    unavailable: () => t('chat:unavailable'),
    ack: (_selection: string) => '',
    webpageOpening: (_url: string) => '',
    webpageError: (_error: AnyError, _url: string) => '',
    newEmailOpening: (_email: string) => '',
    newEmailError: (_error: AnyError, _email: string) => '',
    phoneCallStarting: (_tel: string) => '',
    phoneCallError: (_error: AnyError, _tel: string) => '',
  },
  onOpenLink: (url: string) => new Promise(() => {
    window.open(url, '_blank');
  }),
  onSendMailTo: (email: string) => new Promise(() => {
    const url = `${Environment.mail_url}deeplink/compose?to=${email}`;
    window.open(url, '_blank');
  }),
  onStartPhoneCall: (_tel: string) => new Promise(() => null),
  onUnhandled: (_descriptor: string) => new Promise(() => null),
  onError: (_err: Error) => null,
};