import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { createSlice } from '@reduxjs/toolkit';
import { update } from 'lodash';

const todayDate: any = new Date();

const initialState = {
  value: {
    startIntervalDate: startOfMonth(addMonths(todayDate, -4)).toISOString(),
    endIntervalDate: endOfMonth(addMonths(todayDate, 6)).toISOString(),
    currentDate: todayDate.toISOString(),
    monthData: [],
    data: [],
    selectedData: {},
    personalEvent: {},
  },
};

export const agendaSlice = createSlice({
  name: 'agendaData',
  initialState,
  reducers: {
    setAgendaData: (state: any, action: any) => {
      state.value = { ...state.value, ...action.payload };
    },
    setAgendaFullData: (state: any, action: any) => {
      state.value = {
        ...state.value,
        data: [...action.payload.data],
      };
    },
    addAgendaEvent: (state: any, action: any) => {
      state.value = {
        ...state.value,
        data: [...state.value.data, action.payload.event],
      };
    },
    updateAgendaEvent: (state: any, action: any) => {
      state.value = {
        ...state.value,
        data: [
          ...state.value.data.map((item: any) => {
            return item.id === action.payload.event.id
              ? action.payload.event
              : item;
          }),
        ],
        selectedData: action.payload.event,
      };
    },
    deleteAgendaEvent: (state: any, action: any) => {
      state.value = {
        ...state.value,
        data: [
          ...state.value.data.filter((item: any) => {
            return item.id !== action.payload.event.id;
          }),
        ],
        selectedData: {},
      };
    },
    updatePersonalEvent: (state: any, action: any) => {
      state.value = {
        ...state.value,
        personalEvent: action.payload.event,
      };
    },
  },
});

export const {
  setAgendaData,
  setAgendaFullData,
  addAgendaEvent,
  updateAgendaEvent,
  deleteAgendaEvent,
  updatePersonalEvent,
}: any = agendaSlice.actions;

export const getAgendaData = (state: any) => state.agendaData.value;

export default agendaSlice.reducer;
