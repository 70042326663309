import agendaDataReducer from '../components/pages/agenda/agendaSlice';
import categoriesReducer from '../components/pages/classifieds/components/categoriesSlice';
import categoriesSuggestionReducer from '../components/pages/suggestion/components/categoriesSlice';
import homepageDataReducer from '../components/pages/homepage/homepageSlice';
import locationsSuggestionReducer from '../components/pages/suggestion/components/locationSlice';
import chatBotSessionReducer from '../components/pages/chat/chatWithBot/chatBotSessionSlice';
import classifiedsReducer from '../components/pages/classifieds/classifiedsSlice';
import { configureStore } from '@reduxjs/toolkit';
import isExperiencesReducer from '../components/common/menus/experiences/isExperiencesSlice';
import isToolboxReducer from '../components/common/menus/toolbox/isToolboxSlice';
import newsReducer from '../components/pages/news/newsSlice';
import conversationsReducer from 'components/pages/chat/conversationsSlice';
import activeThreadIdSlice from 'components/pages/chat/activeThreadIdSlice';

export const store = configureStore({
  reducer: {
    agendaData: agendaDataReducer,
    classifieds: classifiedsReducer,
    isToolbox: isToolboxReducer,
    isExperiences: isExperiencesReducer,
    categories: categoriesReducer,
    homepageData: homepageDataReducer,
    news: newsReducer,
    chatBotSession: chatBotSessionReducer,
    locationsSuggestion: locationsSuggestionReducer,
    categoriesSuggestion: categoriesSuggestionReducer,
    conversationsRedux: conversationsReducer,
    activeThreadIdRedux: activeThreadIdSlice,
  },
  devTools: true,
});
