import {
  ApiRequests,
  ApiService,
  AuthenticationService,
  Endpoints,
  Reference,
  useEmittedState,
} from '@ward/library';
import { ChatClient, ChatMessageReceivedEvent, ChatThreadCreatedEvent, ChatThreadPropertiesUpdatedEvent } from '@azure/communication-chat';

import {AzureCommunicationTokenCredential} from '@azure/communication-common';
import ChatModel from './ChatModel';
import EventEmitter from 'events';
import {Me} from './Me';
import {Message, MSMessagerieChatMessage} from './Discussion';
import { StudentReference } from '@ward/library/dist/Models/Student';
import { AcsTokenModel } from '@ward/library/dist/Models/Conversation';
import WhoAmIService from './WhoAmIService';
import { ConversationModel } from './ChatModel/ConversationModel';

type ChatServiceConfig = {
  chatApiUrl: string;
  acsUrl: string;
};

type ChatServiceDeps = {
  api: ApiService;
  authentication: AuthenticationService;
  whoami: WhoAmIService;
  onError: (path: string) => void,
  onServerError: (method: string, path: string, httpCode: number, body: any) => void,
  getNotificationToken?(email: string): Promise<{ token: string }>;
};

type ChatState = {
  userEmail: string;
  cachedUserToken: AcsTokenModel;
  chatClient: ChatClient;
  conversations: Array<ChatModel.Conversation>;
};

export default class ChatService {
  // Config
  private readonly chatApiUrl: string;
  private readonly acsUrl: string;
  // Deps
  private readonly api: ApiService;
  private readonly authentication: AuthenticationService;
  private readonly whoami: WhoAmIService;
  private readonly onError: ChatServiceDeps['onError'];
  private readonly onServerError: ChatServiceDeps['onServerError'];
  private readonly getNotificationToken: ChatServiceDeps['getNotificationToken'];
  // State
  private state: ChatState | null = null;
  private emitter = new EventEmitter();

  constructor(config: ChatServiceConfig, deps: ChatServiceDeps) {
    this.chatApiUrl = config.chatApiUrl;
    this.acsUrl = config.acsUrl;

    this.api = deps.api;
    this.authentication = deps.authentication;
    this.whoami = deps.whoami;
    this.onError = deps.onError;
    this.onServerError = deps.onServerError;
    this.getNotificationToken = deps.getNotificationToken;
  }

  async init() {
    // First configuration of the service at initialization
    this.handleUserChange(this.whoami.getIdentity());

    // Reconfiguration at each change of user
    this.whoami.onIdChange(async () => {
      this.handleUserChange(this.whoami.getIdentity());
    });

    return this;
  }

  // Handle user change during class life
  private handleUserChange(user: Me | null) {
    if (user) {
      this.setUser(user);
    } else {
      this.setNoUser();
    }
  }

  private async setUser(user: Me) {
    const email = user.email;
    const notificationToken = this.getNotificationToken
      ? await this.getNotificationToken(email)
      : null;
    const fcmToken = notificationToken?.token;

    // Load ACS token and request FCM token if needed
    const cachedUserToken = this.state?.cachedUserToken
      ? this.state?.cachedUserToken.expiresOn > new Date().toISOString()
        ? this.state.cachedUserToken
        : await this.getAcsToken(email, 'fcm', fcmToken)
      : await this.getAcsToken(email, 'fcm', fcmToken);

    // Remove after fix
    if(!cachedUserToken) return null;

    // Create chat client
    const chatClient = this.createChatClient(
      this.acsUrl,
      cachedUserToken.token,
    );

    await this.startRealTime(chatClient);

    // Load conversations 
    const conversations = await this.getUserConversations(
        cachedUserToken.token,
      );    

    this.state = {
      chatClient,
      cachedUserToken,
      userEmail: email,
      conversations,
    };

    this.emitter.emit('chat.change');

    console.log(
      '[CHAT SERVICE] Service has been correctly initialized with email',
      email,
    );
  }

  private setNoUser() {
    this.state = null;
    this.emitter.emit('chat.change');
  }

  /*
   * GETTERS
   */

  getState() {
    if (!this.state) throw new Error('No state');
    return this.state;
  }

  getUserId() {
    return this.getState().cachedUserToken.identity;
  }

  findUserToken() {
    return this.getState().cachedUserToken.token;
  }

  searchUserToken() {
    if (this.state) return this.state.cachedUserToken.identity;
    else return null;
  }

  getChatClient() {
    return this.getState().chatClient;
  }

  getConversations() {
    if (this.state) return this.state.conversations;
    else return [];
  }

  addNewConversation(newConversation: ChatModel.Conversation) {
    if (this.state) {
      this.state.conversations = [...this.state.conversations, newConversation];
    }
    this.emitter.emit('chat.change', newConversation);
    this.emitter.emit('chatThreadCreated', newConversation);
  }

  updateConversation(conversationToUpdate: ChatModel.Conversation) {
    const currentConversations = this.getConversations();
    // if (!currentConversations)
      // throw new Error(
        // 'Cannot update conversation without previous conversations',
      // );

    const convIdx = currentConversations.findIndex(
      c => c.id === conversationToUpdate.id,
    );
    if (convIdx === -1) throw new Error('Conversation not found');

    // currentConversations[convIdx] = conversationToUpdate;
    const newConversations = currentConversations.map((item) => (item.id === conversationToUpdate.id ? conversationToUpdate : item));
    if (this.state) this.state.conversations = newConversations;
    this.emitter.emit('chat.change', conversationToUpdate);
    this.emitter.emit('ChatThreadPropertiesUpdatedEvent', conversationToUpdate);
  }

  /*
   * HOOKS
   */

  useIsReady() {
    return useEmittedState(() => !!this.state, this.emitter, 'chat.change');
  }

  private useState() {
    return useEmittedState(() => this.getState(), this.emitter, 'chat.change');
  }

  useConversations() {
    return this.useState().conversations;
  }

  useUserId() {
    return this.useState().cachedUserToken.identity;
  }

  /*
   * CHAT CLIENT
   */

  createChatClient(endpointUrl: string, userAccessToken: string) {
    const tokenCredential = new AzureCommunicationTokenCredential(
      userAccessToken,
    );
    return new ChatClient(endpointUrl, tokenCredential);
  }

  private async startRealTime(chatClient: ChatClient) {
    await chatClient.startRealtimeNotifications();
  }

  private async getAcsToken(
    userEmail: string,
    platform: string = 'fcm',
    notificationToken?: string,
  ) {
    const tokenRequest = ApiRequests.fromEndpoint(Endpoints.chat.getToken, {
      pathParams: null,
      queryParams: {mail: userEmail, platform: platform, notificationToken: notificationToken},
      data: null,
      files: null,
    });
    return this.api.fetch(tokenRequest);
  }

  /*
   * THREADS
   */

  async createThread(topic: string, students: StudentReference[]) {
    const userToken = this.findUserToken();

    const createConversationRequest = ApiRequests.fromEndpoint(Endpoints.chat.createConversation, {
      pathParams: null,
      queryParams: {token: userToken},
      data: {students: students, topic: topic},
      files: null,
    });
    const result = await this.api.fetch(createConversationRequest);
    return result;
  }

  async getUserConversations(
    userToken: string,
  ): Promise<Array<ChatModel.Conversation>> {
    const conversationsRequest = ApiRequests.fromEndpoint(Endpoints.chat.getConversations, {
      pathParams: null,
      queryParams: {token: userToken},
      data: null,
      files: null,
    });
    const conversations = await this.api.fetch(conversationsRequest);
    return conversations;
  }

  async addUsersToThread(threadId: string, students: Array<Reference.Student>, userToken: string) {
    const addUsersRequest = ApiRequests.fromEndpoint(Endpoints.chat.addUsers, {
      pathParams: {id: threadId},
      queryParams: {token: userToken},
      data: students,
      files: null,
    });
    const result = await this.api.fetch(addUsersRequest);
    return result;
  }

  async removeUserFromThread(threadId: string, student: Reference.Student, userToken: string) {
    const removeUSerRequest = ApiRequests.fromEndpoint(Endpoints.chat.removeUser, {
      pathParams: {id: threadId},
      queryParams: {token: userToken},
      data: student,
      files: null,
    });
    const result = await this.api.fetch(removeUSerRequest);
    return result;
  }

  async updateThreadTopic(threadId: string, topic: string, userToken: string) {
    const updateTopicRequest = ApiRequests.fromEndpoint(Endpoints.chat.updateTopic, {
      pathParams: {id: threadId},
      queryParams: {token: userToken},
      data: {topic: topic},
      files: null,
    });

    const result = await this.api.fetch(updateTopicRequest);
    return result;
  }

  // update conversations when a new message is received without reloading all conversations from request
  updateConversationsFromEvent(event: ChatMessageReceivedEvent, conversations: Array<ChatModel.Conversation>, activeThreadId: string) {
    const conversation = conversations.find((c) => c.id === event.threadId);
    if (!conversation) throw new Error('Conversation not found');
    const conversationIndex = conversations.indexOf(conversation);
    const participants = conversations[conversationIndex].participants;
    const isNotMine = conversation.participants.some((participant) => participant.CId === event.senderDisplayName);
    const me = this.whoami.getIdentity();
    const author = participants.find((p) => p.CId === event.senderDisplayName);
    const newDateMessage = new Date(event.createdOn);
    const formattedDate = newDateMessage.toISOString();
    const newLastmessage = {
      id: event.id,
      at: formattedDate,
      author: { name: isNotMine ? author?.firstname + ' ' + author?.lastname : me?.firstname + ' ' + me?.lastname, image: isNotMine ? author?.photo : me?.photo },
      text: event.message,
    } as Message;
    let unread_messages_nb = 0;
    if (isNotMine && activeThreadId !== event.threadId) {
      unread_messages_nb = conversations[conversationIndex].unread_messages_nb + 1;
    }
    const newConversations = conversations.map((item) => ({
      ...item,
      last_message: item.id === conversation.id ? newLastmessage : item.last_message,
      unread_messages_nb: item.id === conversation.id ? unread_messages_nb : item.unread_messages_nb,
    }))

    return newConversations;
  }

  updateConversationsPropertiesFromEvent(event: ChatThreadPropertiesUpdatedEvent) {
    const conversations = this.getConversations();
    const conversation = conversations.find((c) => c.id === event.threadId);
    if (!conversation) throw new Error('Conversation not found');
    const newConversations = conversations.map((item) => ({
      ...item,
      label: item.id === conversation.id ? event.properties.topic : item.label,
    }))

    return newConversations;
  }

  addedConversationFromEvent(event: ChatThreadCreatedEvent, conversations: Array<ChatModel.Conversation>) {
    const newConversation = {
      blocked_by_me: false,
      id: event.threadId,
      image: "",
      is_moderator: true,
      label: event.properties.topic,
      last_message: null,
      participants: [],
      type: event.participants.length > 2 ? 'group' : 'single',
      unread_messages_nb: 0,
    } as ChatModel.Conversation;

    const newConversations = [...conversations, newConversation];

    return newConversations;
  }

  /*
   * MESSAGES
   */

    async getMessagesByThread(
      threadId: string,
      nbResults: number | null,
      userToken: string | null = null,
    ) {
      userToken = userToken ? userToken : this.findUserToken();
      nbResults = nbResults ? nbResults : 100;
    const getConversationRequest = ApiRequests.fromEndpoint(Endpoints.chat.getConversation, {
      pathParams: {id: threadId},
      queryParams: {token: userToken},
      data: null,
      files: null,
    });
    const result = await this.api.fetch(getConversationRequest);
      return result;
  }

  async getConversationMessages(
    threadId: string,
    nbResults: number | null = null,
    userToken: string,
  ): Promise<Array<Message>> {
    if (!this.state) throw new Error('No conversations found');

    const getMessagesRequest = ApiRequests.fromEndpoint(Endpoints.chat.getMessages, {
      pathParams: {id: threadId},
      queryParams: { token: userToken, limit: nbResults ? nbResults : 20 },
      data: null,
      files: null,
    });
    const result = await this.api.fetch(getMessagesRequest);
    return result;
  }

  createMessage(messageId: string | number | null, content: string) {

    return {
      id: messageId,
      at: new Date(),
      author: 'me',
      text: content,
    } as Message;
  }

  async getThreadLastMessage(
    threadId: string,
    userIdentity: string,
    userToken: string | null = null,
  ) {

    const threadInfo : ConversationModel = await this.getMessagesByThread(
      threadId,
      1,
      userToken,
    );

    if (!threadInfo) throw new Error('No thread found');
    
    const lastChatMessage = threadInfo.last_message;

    return lastChatMessage;
  }

  async sendChatMessage(threadId: string, message: MSMessagerieChatMessage, userToken: string,) {
    const sendMessageRequest = ApiRequests.fromEndpoint(Endpoints.chat.sendMessage, {
      pathParams: {id: threadId},
      queryParams: {token: userToken},
      data: message,
      files: null,
    });
    const result = await this.api.fetch(sendMessageRequest);
    return result;
  }

  sendTypingNotification(threadId: string) {
    const chatThreadClient = this.getChatClient().getChatThreadClient(threadId);
    chatThreadClient.sendTypingNotification();
  }

  readLastMessages(threadId: string, messages: Message[]) {
    const chatThreadClient = this.getChatClient().getChatThreadClient(threadId);
    const messagesNotByMe = messages.filter(m => m.author !== 'me');
    if (messagesNotByMe.length > 0) {
      chatThreadClient.sendReadReceipt({chatMessageId: messagesNotByMe[0].id});
    }
  }

  async getNbUnreadMessages(threadId: string, userToken: string | null = null) {
    const method = 'GET' as const;
    const route = `${this.chatApiUrl}/threads/${threadId}/messages/notread/count`;
    const authorization = await this.authentication.getAuthorizationHeader();

    const requestOptions = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization,
        'X-User-ACS-Token': userToken ? userToken : this.findUserToken(),
      },
      // body: JSON.stringify({
        // userIdentity: this.getUserId(),
        // token: this.findUserToken(),
        // displayName: '',
        // email: '',
      // }),
    };

    try {
      const response = await fetch(route, requestOptions);
      if (response.ok) {
        this.emitter.emit('chat.change');
        const array = await response.json();
        return array //[array.length - 1];
      } else {
        const data = response.bodyUsed ? await response.text() : '';
        throw this.onServerError(method, route, response.status, data);
      }
    } catch (error) {
      throw this.onError(route);
    }
  }

  getTotalUnreadMessages(conversations: Array<ChatModel.Conversation>) {
    const total = conversations.reduce((acc, cur) => acc + cur.unread_messages_nb, 0);
    return total
  }

  /*
   * STUDENT
   */

  async fetchStudent(studentId: number | string) {
    const request = ApiRequests.fromEndpoint(Endpoints.students.getStudent, {
      pathParams: {id: studentId},
      queryParams: null,
      data: null,
      files: null,
    });
    const result = await this.api.fetch(request);
    return result;
  }

  async fetchStudents(ids: Array<number | string>) {
    const request = ApiRequests.fromEndpoint(Endpoints.students.getStudents, {
      pathParams: null,
      queryParams: {ids},
      data: null,
      files: null,
    });
    return this.api.fetch(request);
  }

  async retrieveStudentsFromThread(
    participants: Array<ChatModel.Participant>,
    currentUserIdentity: string,
  ) {
    const participantsWithoutMe = participants.filter(
      p => p.user.id !== currentUserIdentity,
    );
    const studentsIds = participantsWithoutMe.map(p => {
      return p.displayName;
    });
    return this.fetchStudents(studentsIds);
  }

  getSuggestions() {
    const conversations = this.getConversations().slice(0, 10);
    const allParticipants = conversations.flatMap(c => c.participants);

    return allParticipants.filter(
      (v, i, a) => a.findIndex(t => t.id === v.id) === i,
    );
  }
}
