import { Button, Stack, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { SxProps, Theme } from '@mui/system';

import { t } from 'i18next';

const containerStyle: SxProps<Theme> = {
  width: 'calc((420px * 2) + (12px * 2))',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
};

const choiceStyle: SxProps<Theme> = {
  display: 'flex',
  flex: '0 0 420px',
  height: 48,
  marginRight: 1.5,
  marginBottom: 1.5,
  alignItems: 'center',
  justifyContent: 'center',
  color: theme => theme.palette.primary.contrastText,
  background: '#05156B',
  border: '1px solid',
  borderColor: '#6678D8',
  borderRadius: '24px',
  textTransform: 'none',
  '&:hover': {
    background: '#6678D8',
  },
};

const choiceSelectedStyle: SxProps<Theme> = {
  background: '#6678D8',
};

const choiceLabelStyle: SxProps<Theme> = {
  fontSize: 14,
  fontWeight: 600,
};

export type SubCriteriaValue = 'myClass' | 'classes' | 'topic' | 'association';

export type SearchCriteria = {
  id: number,
  label: string,
  value: 'class' | 'countryCode' | 'hobby' | 'companySiret' | 'assistance' | 'project',
  subCriteria?: Array<{
    id: number,
    label: string,
    value: SubCriteriaValue,
    subCriteria?: any,
  }>
};

const CRITERIA: SearchCriteria[] = [
  {
    id: 0,
    label: t('search:class'),
    value: 'class',
    subCriteria: [
      {
        id: 7,
        label: t('search:myclass'),
        value: 'myClass',
      },
      {
        id: 8,
        label: t('search:othersclasses'),
        value: 'classes',
      },
    ],
  },
  {
    id: 1,
    label: t('search:country'),
    value: 'countryCode',
  },
  {
    id: 2,
    label: t('search:companies'),
    value: 'companySiret',
  },
  {
    id: 3,
    label: t('search:projects'),
    value: 'project',
    subCriteria: [
      {
        id: 7,
        label: t('search:topic'),
        value: 'topic',
      },
      {
        id: 8,
        label: t('search:association'),
        value: 'association',
      },
    ],
  },
  {
    id: 4,
    label: t('search:hobbies'),
    value: 'hobby',
  },
  {
    id: 5,
    label: t('search:assistances'),
    value: 'assistance',
  },
];

type Step2Props = {
  selected?: SearchCriteria['value'] | SubCriteriaValue,
  setSelected: Function,
  setValue: Function,
};

const Step2: FC<Step2Props> = ({ selected, setSelected }) => {
  const [parentCriteria, setParentCriteria] = useState<SearchCriteria['value']>();

  const handleClickCriteria = (value: SearchCriteria['value'], isParent: boolean) => {
    if(isParent){
      return setParentCriteria(value);
    }
    setSelected(value);
  };

  const handleClickSubCriteria = (value: SubCriteriaValue) => {
    setSelected(value);
    if(value !== 'myClass'){
      setParentCriteria(undefined);
    }
  };

  const subCriteria = parentCriteria
    ? CRITERIA.find((parent) => parent.value === parentCriteria)?.subCriteria
    : null;

  return ( 
    <Stack sx={containerStyle}>
      {!parentCriteria && CRITERIA?.map((choice) =>
        <Button
          key={choice.id}
          disableRipple
          disableFocusRipple
          onClick={() => handleClickCriteria(choice.value, !!choice.subCriteria)}
          sx={[choiceStyle, selected === choice.value ? choiceSelectedStyle : {}]}
        >
          <Typography sx={choiceLabelStyle}>
            {choice.label}
          </Typography>
        </Button>
      )}
      {subCriteria && subCriteria.map((choice) =>
        <Button
          key={choice.id}
          disableRipple
          disableFocusRipple
          onClick={() => handleClickSubCriteria(choice.value)}
          sx={[choiceStyle, selected === choice.value ? choiceSelectedStyle : {}]}
        >
          <Typography sx={choiceLabelStyle}>
            {choice.label}
          </Typography>
        </Button>
      )}
    </Stack>
  );
};

export default Step2;