import { Avatar, Box } from '@mui/material';
import { SxProps, Theme, alpha } from '@mui/system';

const avatarContainerStyle: SxProps<Theme> = {
    padding: '3px',
    borderRadius: '50%',
  // background: theme => theme.palette.gradient.main,
  background: 'linear-gradient(160deg, #ab0926, #0046c9)',
};
  
const avatarContainerWhiteStyle: SxProps<Theme> = {
    background: theme => alpha(theme.palette.primary.contrastText, .7),
};

type CommentsAvatarProps = {
  src: string;
  alt?: string;
  size?: number;
  whiteBorder?: boolean,
};

const CommentsAvatar = ({
  src,
  alt = '',
  size = 44,
  whiteBorder = false,
}: CommentsAvatarProps) =>
  <Box sx={[
    avatarContainerStyle,
    whiteBorder ? avatarContainerWhiteStyle : {},
    { width: size, height: size }
  ]}>
    <Avatar
      sx={{ width: size - 6, height: size - 6 }}
      src={src}
      alt={alt}
    />
  </Box>

export default CommentsAvatar;
