import { ApiService, RequestStore } from '@ward/library';

import Environment from '../../utilities/Environment';
import authentication from './authentication';
import networkClient from '../../utilities/networkClient';

// / From config
const baseUrl = Environment.api_url;
const store = new RequestStore();
const api = new ApiService(
  {
    url: baseUrl,
    networkClient: networkClient,
    store,
    debug: true,
    defaultFetchOptions: {
      forceFetching: true,
    },
  },
  {
    authentication: authentication,
  },
);

export default api;
