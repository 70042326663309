import {ApiRequests, Endpoints} from '@ward/library';

import { AbuseReasonsContentType } from '@ward/library/dist/Endpoints/abuse-reasons';

// Report

function getAbuseReasons(contentType: AbuseReasonsContentType) {
  return ApiRequests.fromEndpoint(Endpoints.abuseReasons.getAbuseReasons, {
    pathParams: null,
    queryParams: { contentType },
    data: null,
    files: null,
  });
}

const requests = {
  getAbuseReasons,
};

export default requests;