import { useEffect, useState } from 'react';
import authentication from '../../../core/api/authentication';

const useLoggedIn = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  const logout = () => {
    // @ts-ignore
    authentication.clean();
    setIsLoggedIn(false);
    setIsLoading(false);
  }

  useEffect(() => {
    // @ts-ignore
    const isLogged = authentication.getKeysPresence();
    setIsLoggedIn(isLogged);
    const checkAuth = async () => {
      try {
        // @ts-ignore
        const autho = await authentication.getAuthorizationHeader()
        if (autho.length <= 20) { // `Bearer undefined|null||`
          logout();
        }
      } catch {
        logout();
      };
    }
    if (isLogged) {
      checkAuth()
    } else {
      setIsLoading(false);
    }
  }, []);

  return {
    isLoading,
    isLoggedIn,
  };
}

export default useLoggedIn;