import { Components, PaletteOptions, Theme, alpha } from '@mui/material';

import { createTheme } from '@mui/material/styles';
import { lighten } from '@mui/material/styles';

const colorPrimaryMain = '#06166B';
const colorPrimaryDark = '#00094D';
const colorPrimaryLight = '#BCC5E3';
const colorTextPrimary = '#ffffff';
// const defaultGradient = 'linear-gradient(160deg, #ab0926, #0046c9)';
const defaultGradient = 'rgba(0, 70, 201, 1)';
const defaultGradientHover = `linear-gradient(160deg, ${lighten('#ff0000', .1)}, ${lighten('#0046c9', .1)})`;

const palette: PaletteOptions = {
  primary: {
    main: colorPrimaryMain,
    dark: colorPrimaryDark,
    light: colorPrimaryLight,
  },
  secondary: {
    main: '#FF0000',
    dark: '#D50C21',
  },
  background: {
    default: colorPrimaryMain,
    paper: '#000650',
  },
  text: {
    primary: colorTextPrimary,
  },
  gradient: {
    main: defaultGradient,
    mainHover: defaultGradientHover,
  },
};

export const components: Components = {
  MuiButton: {
    styleOverrides: {
      contained: {
        textDecoration: 'none',
        fontWeight: 500,
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        }
      },
      containedSizeSmall: {
        height: 32,
        padding: '0 24px',
        borderRadius: '16px',
        fontSize: 10,
      },
      containedSizeMedium: {
        height: 36,
        padding: '0 24px',
        borderRadius: '18px',
      },
      containedPrimary: {
        // background: defaultGradient,
        background: 'linear-gradient(160deg, #ab0926, #0046c9)',
        '&:hover': {
          background: defaultGradientHover,
        },
        '&.Mui-disabled': {
          background: '#dde1ec',
          color: colorTextPrimary,
        },
      },
      containedSecondary: {
        background: colorPrimaryLight,
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      margin: 'normal',
    },
    styleOverrides: {
      marginNormal: {
        marginTop: 0,
        marginBottom: '24px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        color: colorPrimaryMain,
        fontWeight: 600,
        fontSize: 15,
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: alpha(colorPrimaryMain, 0.2),
          borderWidth: 1,
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: alpha(colorPrimaryMain, 0.5),
          borderWidth: 1,
        },
      },
      input: {
        padding: '15px 25px !important',
        borderRadius: '8px',
      },
      inputSizeSmall: {
        padding: '9px 25px !important',
      },
      inputMultiline: {
        height: 'auto !important',
      },
      inputAdornedEnd: {
        paddingRight: '15px !important',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        borderColor: alpha(colorPrimaryMain, 0.1),
        borderRadius: '8px',
        padding: '0 15px',

        // fix legend background
        legend: {
          fontWeight: 400,
        },
      },
    },
  },
  MuiInputAdornment: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-fontSizeMedium': {
          width: 25,
          height: 25,
        },
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        transform: 'translate(25px, 15px) scale(1)',
      },
      shrink: {
        transform: 'translate(25px, -9px) scale(.75)',
      },
      asterisk: {
        display: 'none !important',
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        backgroundColor: `${alpha('#eef2ff', 1)} !important`,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: '8px 25px',
        fontWeight: 500,
        color: colorPrimaryMain,
        '&:hover': {
          backgroundColor: alpha(colorPrimaryMain, 0.05),
        },
        '&.Mui-selected': {
          backgroundColor: alpha(colorPrimaryMain, 0.1),
        },
      },
    },
  },
  MuiBackdrop: {
    styleOverrides: {
      root: {
        marginTop: '78px',
        background: alpha(colorPrimaryMain, 0.8),
        backdropFilter: 'blur(24.16320037841797px)',
      },
      invisible: {
        background: 'transparent',
        backdropFilter: 'none',
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        padding: 0,
        width: '36px',
        height: '17px',
        marginRight: '0',
        '& .MuiSwitch-thumb': {
          width: '11px',
          height: '11px',
        },
        '& .MuiSwitch-switchBase': {
          top: '-7px',
          padding: '10px 0px',
          left: '2px',
          color: '#ffffff',
          '&.Mui-checked': {
            color: `${colorPrimaryMain} !important`,
            '&+.MuiSwitch-track':{
              background: 'transparent',
              border: '1px solid',
              borderColor: colorPrimaryMain,
              borderRadius: '36px',
              opacity: 1,
            },
          },
        },
        '& .MuiSwitch-track': {
          background: colorPrimaryMain,
          borderRadius: '36px',
          opacity: 1,
        },
      },
    },
  }
};

export const theme: Theme = createTheme({
  palette,
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 16,
    htmlFontSize: 16,
    fontWeightBold: 700,
    fontWeightSemiBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,

    appname: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
      fontSize: '1.25rem',
      textTransform: 'uppercase',
    },
    topbar: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 600,
      fontSize: '0.625rem',
      letterSpacing: '.250rem',
      textTransform: 'uppercase',
    },
    h1: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 300,
      fontSize: '20px',
    },
    h2: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
      fontSize: '20px',
      letterSpacing: '.100rem',
    },
    h3: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
      fontSize: '16px',
      letterSpacing: '.250rem',
    },
    body1: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 300,
      fontSize: '0.875rem',
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
      fontSize: '0.750rem',
    },
    button: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
      fontSize: '0.9rem',
      letterSpacing: '.100rem',
      textTransform: 'uppercase',
    },
    overline: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
      fontSize: '.4375rem',
      letterSpacing: '.100rem',
      textTransform: 'uppercase',
      lineHeight: '1.5em',
    },
    subtitle2: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 700,
      fontSize: '.5625rem',
    },

    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle1: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 500,
      fontSize: '1.25rem',
      letterSpacing: '.100rem',
    },
    caption: {},
  },
  breakpoints: {
    values: {
      xs: 700,
      sm: 1080,
      md: 1440,
      lg: 1600,
      xl: 1870,
    },
  },
  components,
});

export default theme;
