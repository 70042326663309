import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  value: {
    locations: [],
  },
};

export const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocation: (state: any, action: any) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setLocation }: any =
  locationSlice.actions;

export const getLocation = (state: any) => {
  return state.locationsSuggestion.value.locations;
};

const locationsSuggestionReducer = locationSlice.reducer;

export default locationsSuggestionReducer;
