import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  value: {
    data: [],
    ReturnData: [],
    Comments:[]
  },
};

export const classifiedsSlice = createSlice({
  name: 'classifieds',
  initialState,
  reducers: {
    setClassifiedsList: (state: any, action: any) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setClassifiedsList }: any = classifiedsSlice.actions;

export const getClassifiedsList = (state: any) => state.classifieds.value;

export default classifiedsSlice.reducer;
