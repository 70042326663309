import {
  FileToUpload,
  NetworkClient,
  NetworkClientMethod,
  NewtorkClientHeaders,
} from '@ward/library';
import i18n from './i18n';

// FetchError happens when server is not accessible
export class FetchError extends Error {
  path: string;
  constructor(path: string) {
    super(`Server is not reachable`);
    this.name = 'FetchError';
    this.path = path;
  }
}

// ServerError happens when server is accessible, but returns error codes
export class ServerError extends Error {
  method: string;
  path: string;
  httpCode: number;
  body: any;
  constructor(method: string, path: string, httpCode: number, body: any) {
    super(`Server respond with error`);
    this.name = 'ServerError';
    this.method = method;
    this.path = path;
    this.httpCode = httpCode;
    this.body = body;
  }
}

function handleHeaders(input: NewtorkClientHeaders | undefined): Headers {
  let headers: Headers = new Headers();
  if (input) {
    const entries = Object.entries(input);
    entries.forEach(([key, value]) => headers.append(key, value));
  }
  return headers;
}

async function handleFetchResponse(
  method: NetworkClientMethod,
  url: string,
  response: Response,
) {
  const result = await extractResponseContent(response);
  if (response.ok) {
    return result;
  } else if (response.status === 403 || response.status === 401) {
    localStorage.clear();
    window.location.href = '/';
  } else {
    throw new ServerError(method, url, response.status, result);
  }
}

async function extractResponseContent(response: Response) {
  let result: any;
  const contentType = response.headers.get('Content-Type') || '';
  if (response.status === 204 || !contentType) {
    return null;
  } else if (
    contentType.includes('text/plain') ||
    contentType.includes('text/html')
  ) {
    result = await response.text();
  } else if (contentType.includes('application/json')) {
    result = await response.json();
  } else {
    throw new Error(`Invalid content type in response : ${contentType}`);
  }
  return result;
}

const getFormData = (bodyData: any, files: [string, FileToUpload | null][]) => {
  const formData = new FormData();
  if (!!bodyData) {
    const bodyField = Object.entries(bodyData);
    bodyField.forEach(([field, value]) => {
      formData.append(field, value as string);
    });
  }
  files.forEach(([field, file]) => {
    if (file) {
      formData.append(field, file as Blob);
    }
  });
  return formData;
};

const networkClient: NetworkClient = async (method, url, options) => {
  const headers = handleHeaders(options.headers);
  // if options.headers contains a language, it will be used
  // otherwise, the language from the i18n library will be used
  // headers.append('Accept-Language', options.headers?.language || i18n.language);
  headers.append('Accept-Language', i18n.language);

  const { bodyData, bodyFiles } = options;
  const hasBodyData = !!bodyData;
  const files = Object.entries(bodyFiles || {});

  let body: RequestInit['body'];

  if (files.length) {
    body = getFormData(bodyData, files);
  } else if (hasBodyData) {
    if (typeof bodyData === 'object') {
      headers.append('Content-type', 'application/json');
      body = JSON.stringify(bodyData);
    } else if (typeof bodyData === 'string') {
      body = bodyData;
    } else {
      throw new Error(`Can send following content in request : ${bodyData}`);
    }
  }

  let response = null;
  let error = null;
  for (let i = 0; i < 5 && !response; i++) {
    try {
      response = await fetch(url, { method, headers, body });
      const resp = handleFetchResponse(method, url, response);
      return resp;
    } catch (err) {
      error = err;
    }
  }
  if (
    error instanceof TypeError &&
    error.message === 'Network request failed'
  ) {
    throw new FetchError(url);
  } else {
    throw error;
  }
};

export default networkClient;
