import { AbuseReasonsContentType } from "@ward/library/dist/Endpoints/abuse-reasons";
import { ApiRequests } from "@ward/library";

function getComments(endpoint: any, contentType: AbuseReasonsContentType, id: number) {
  return ApiRequests.fromEndpoint(endpoint, {
    pathParams: { [contentType]: id },
    queryParams: null,
    data: null,
    files: null,
  });
}

function getAnswers(endpoint: any, contentType: AbuseReasonsContentType, id: number, comment: number) {
  return ApiRequests.fromEndpoint(endpoint, {
    pathParams: { [contentType]: id, comment },
    queryParams: null,
    data: null,
    files: null,
  });
}

function addComment(endpoint: any, contentType: AbuseReasonsContentType, id: number, content: string) {
  return ApiRequests.fromEndpoint(endpoint, {
    pathParams: { [contentType]: id },
    queryParams: null,
    data: { content },
    files: null,
  });
}

function addAnswer(endpoint: any, contentType: AbuseReasonsContentType, id: number, content: string, comment: number) {
  return ApiRequests.fromEndpoint(endpoint, {
    pathParams: { [contentType]: id, comment },
    queryParams: null,
    data: { content },
    files: null,
  });
}

function reportComment(endpoint: any, contentType: AbuseReasonsContentType, id: number, comment: number, reason: string) {
  return ApiRequests.fromEndpoint(endpoint, {
    pathParams: { [contentType]: id, comment },
    queryParams: null,
    data: { reason },
    files: null,
  });
}

function reportAnswer(endpoint: any, contentType: AbuseReasonsContentType, id: number, comment: number, answer: number, reason: string) {
  return ApiRequests.fromEndpoint(endpoint, {
    pathParams: { [contentType]: id, comment, answer },
    queryParams: null,
    data: { reason },
    files: null,
  });
}

const requests = {
  getComments,
  getAnswers,
  addComment,
  addAnswer,
  reportComment,
  reportAnswer,
};

export default requests;