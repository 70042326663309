import { Resources } from '../utils';
import absences from './absences.json';
import ads from './ads.json';
import agenda from './agenda.json';
import business from './business.json';
import chat from './chat.json';
import common from './common.json';
import contact from './contact.json';
import homepage from './homepage.json';
import informations from './informations.json';
import menu from './menu.json';
import news from './news.json';
import profile from './profile.json';
import search from './search.json';
import suggestion from './suggestion.json'
import transcripts from './transcripts.json';
import partnerUniversities from './partner_universities.json';

const en: Resources = {
  common,
  menu,
  homepage,
  absences,
  news,
  agenda,
  chat,
  transcripts,
  ads,
  search,
  contact,
  profile,
  business,
  informations,
  suggestion,
  partnerUniversities,
};

export default en;
