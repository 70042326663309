import { SxProps, Theme, ThemeProvider, createTheme } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { theme as baseTheme, components } from '../theme';
import { FC } from 'react';
import { Icon } from 'core/utils';
import { getLocale } from 'utilities/utils';

const theme: Theme = createTheme({
  palette: {
    primary: {
      main: baseTheme.palette.primary.main,
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 16,
    body1: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeight: 300,
      fontSize: '0.875rem',
      lineHeight: '1.15em',
    },
  },
  components: {
    ...components,
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.primary.main,
        },
      },
    },
  },
});

const iconStyle: SxProps<Theme> = {
  path: {
    fill: theme => theme.palette.primary.main,
  },
};

type DatePickerComponentProps = {
  label: string,
  onChange: (value: Date | null) => void,
  value: Date | null,
  size?: 'small' | 'medium',
  required?: boolean,
}

const DatePickerComponent: FC<DatePickerComponentProps> = ({
  label,
  onChange,
  value,
  size = 'medium',
  required = false,
}) =>
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale()}>
      <DatePicker
        // renderInput={(props: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) =>
        //   <TextField
        //     {...props}
        //     size={size}
        //     margin='normal'
        //     fullWidth
        //     required={required}
        //   />
        // }
        label={label}
        value={value}
        onChange={onChange}
        // components={{
        //   OpenPickerIcon: () =>
        //     <Box sx={iconStyle}>
        //       <Icon icon='feature_date' size={20} />
        //     </Box>
        // }}
      />
    </LocalizationProvider>
  </ThemeProvider>

export default DatePickerComponent;
