import {
  AnyError,
  ApiService,
  ChatbotSession,
} from '@ward/library';

import { BotScriptConfig } from '@ward/library/dist/ChatbotSession';

export type ChatbotConfig = {
  scriptConfig: BotScriptConfig;
  apiService: ApiService;
  messages: {
    welcome: () => string;
    unavailable: () => string;
    ack: (selection: string) => string;
    webpageOpening: (url: string) => string;
    webpageError: (error: AnyError, url: string) => string;
    newEmailOpening: (email: string) => string;
    newEmailError: (error: AnyError, email: string) => string;
    phoneCallStarting: (tel: string) => string;
    phoneCallError: (error: AnyError, tel: string) => string;
  };
  onOpenLink: (url: string) => Promise<void>;
  onSendMailTo: (email: string) => Promise<void>;
  onStartPhoneCall: (tel: string) => Promise<void>;
  onUnhandled: (descriptor: string) => void;
  onError: (err: Error) => void;
};

type ChatBotServiceConfig = ChatbotConfig;

export default class ChatBotService {
  // Config
  private readonly configuration: ChatbotConfig;

  // State
  private session: any | null = null;

  constructor(config: ChatBotServiceConfig) {
    this.configuration = config;
  }

  init() {
    return this;
  }

  async createSession(onChangeCallback: Function) {
    this.session = await new ChatbotSession(this.configuration);
    this.setOnChange(onChangeCallback);
    return this.session;
  }

  select(key: number){
    this.session.select(key);
  }

  setOnChange(onChangeCallback: Function) {
    this.session.onChange(onChangeCallback);
  }

  /*
   * GETTERS
   */

  getDisplay() {
    if(!this.session) return;
    return this.session.getDisplay();
  }

  isStarted() {
    return !!this.session;
  }
}
