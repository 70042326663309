import { Box, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { SxProps, Theme } from '@mui/system';

import { AbuseReasonsContentType } from '@ward/library/dist/Endpoints/abuse-reasons';
import Comment from './Comment';
import CommentForm from './CommentForm';
import { Model } from '@ward/library';
import Services from 'utilities/Services';
import requests from './requests';

const containerStyle: SxProps<Theme> = {
  paddingTop: '90px',
  paddingBottom: 3,
};

const titleContainerStyle: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  // background: theme => theme.palette.gradient.main,
  background: 'linear-gradient(160deg, #ab0926, #0046c9)',
  paddingLeft: 'calc(33% + 5%)',
  paddingRight: '5%',
  textAlign: 'center',
  justifyContent: 'center',
  height: 46,
};

const titleStyle: SxProps<Theme> = {
  fontSize: 14,
  fontWeight: 500,
};

export type CommentsEnpoints = {
  getComments: any,
  getCommentAnswers: any,
  addComment: any,
  addCommentAnswers: any,
  reportComment: any,
  reportCommentAnswer: any,
};

type CommentsProps = {
  id: number,
  endpoints: CommentsEnpoints,
  contentType: AbuseReasonsContentType,
};

const Comments: FC<CommentsProps> = ({ id, endpoints, contentType }) => {
  const { t } = useTranslation<Namespace>('common');
  const { data } = Services.useAll();
  const [activeComment, setActiveComment] = useState(null);
  const [comments, setComments] = useState<Model.Comment[]>([]);

  const fetchComments = useCallback(async () => {
    const response = await data.fetch(
      requests.getComments(endpoints.getComments, contentType, id)
    ) as Model.Comment[];
    if (!response) return;
    setComments(response);
  }, [contentType, data, endpoints.getComments, id]);

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const submitComment = async (content: string) => {
    const response = await data.fetch(
      requests.addComment(endpoints.addComment, contentType, id, content)
    );
    if (!response) return;
    fetchComments();
  };

  return (
    <Box sx={containerStyle}>
      <Stack sx={titleContainerStyle}>
        <Typography sx={titleStyle} variant='h2'>
          {t('comments')}
        </Typography>
      </Stack>
      <CommentForm handleSubmit={submitComment} id={id} />
      <Box>
        {comments.map((comment: Model.Comment) => (
          <Comment
            key={comment.id}
            id={id}
            comment={comment}
            activeComment={activeComment}
            setActiveComment={setActiveComment}
            endpoints={endpoints}
            contentType={contentType}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Comments;
