import {ApiRequests, Endpoints} from '@ward/library';

import { SmartSearchQueryPayload } from '@ward/library/dist/Endpoints/students';

function getMyTeachers() {
  return ApiRequests.fromEndpoint(Endpoints.students.getStudentTeachers, {
    pathParams: {id: 'current'},
    queryParams: {},
    data: null,
    files: null,
  });
}

function searchStudents(smartsearch: SmartSearchQueryPayload) {
  return ApiRequests.fromEndpoint(Endpoints.students.getStudents, {
    pathParams: null,
    queryParams: {smartsearch},
    data: null,
    files: null,
  });
}

function getClasses() {
  return ApiRequests.fromEndpoint(Endpoints.classes.getClasses, {
    pathParams: null,
    queryParams: null,
    data: null,
    files: null,
  });
}

function getCountries() {
  return ApiRequests.fromEndpoint(Endpoints.countries.getCountries, {
    pathParams: null,
    queryParams: null,
    data: null,
    files: null,
  });
}

const requests = {
  searchStudents,
  getClasses,
  getCountries,
  getMyTeachers,
};

export default requests;
