import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  value: false,
};

export const isExperiencesSlice = createSlice({
  name: 'isExperiences',
  initialState,
  reducers: {
    setIsExperiences: (state: any, action: any) => {
      state.value = action.payload;
    },
  },
});

export const { setIsExperiences }: any = isExperiencesSlice.actions;

export const getIsExperiences = (state: any) => state.isExperiences.value;

export default isExperiencesSlice.reducer;
