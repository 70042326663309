import { Box, Button, Grid, Stack, SxProps, Theme, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';

import { APPBAR_HEIGHT } from '../../appTopBar/AppTopBar';
import { Icon } from '../../../../core/utils';
import { Link } from 'react-router-dom';
import LoadingView from '../../LoadingView';
import { PageContainer } from '../..';
import Services from 'utilities/Services';
import { Toolbox } from '@ward/library';
import { alpha } from '@mui/system';
import { keyframes } from '@emotion/react';
import requests from '../requests';
import { setIsToolbox } from './isToolboxSlice';
import themeVariable from '../../../theme';
import { useDispatch } from 'react-redux';

const slideDown = keyframes`
  from {top: -100vh;}
  to {top: 0;}
`;

const containerStyle: SxProps<Theme> = {
  width: themeVariable.breakpoints.values.lg,
  flexWrap: 'wrap',
};

const bgImgStyle: SxProps<Theme> = {
  background: 'linear-gradient(180deg, rgba(255,0,0,.65)10%,rgba(0,70,201,.6)65%)',
  width: '100%',
  height: '100%',
  position: 'fixed',
  zIndex: 900,
  top: 0,
  paddingTop: `${APPBAR_HEIGHT}px`,
  backdropFilter: 'blur(16px)',
  animation: `${slideDown} 400ms`,
  [themeVariable.breakpoints.down('lg')]: {
    overflow: 'auto',
  },
};

const groupStyle: SxProps<Theme> = {
  marginRight: 8,
  marginBottom: 2,
};

const titleStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  fontSize: 16,
  fontWeight: 700,
  margin: '30px 0 20px 0',
  letterSpacing: 0,
  lineHeight: '18px',
  textTransform: 'uppercase',
  borderRadius: '25px',
};

const buttonStyle: SxProps<Theme> = {
  color: theme => theme.palette.primary.contrastText,
  fontSize: 14,
  margin: '20px 14px 14px 0',
  letterSpacing: 0,
  lineHeight: '18px',
  padding: '8px 23px',
  borderRadius: '25px',
  textTransform: 'capitalize',
  background: theme => alpha(theme.palette.primary.contrastText, .32),
  "&:hover": {
    background: theme => alpha(theme.palette.primary.contrastText, .42),
  },
};

const boxStyle: SxProps<Theme> = {
  display: 'flex',
  width: 138,
  height: 133,
  padding: 1,
  marginRight: 1.5,
  marginBottom: 1.5,
  borderRadius: '17px',
  background: theme => theme.palette.primary.contrastText,
  color: theme => theme.palette.primary.main,
  '&:hover': {
    background: theme => alpha(theme.palette.primary.contrastText, .9),
  },
};

const boxContentStyle: SxProps<Theme> = {
  textAlign: 'center',
  alignSelf: 'center',
  margin: '0 auto',
};

const boxLabelStyle: SxProps<Theme> = {
  fontSize: 10,
  fontWeight: 600,
  paddingTop: '14px',
  textAlign: 'center',
};

const iconStyle: SxProps<Theme> = {
  height: 55,
  color: theme => alpha(theme.palette.primary.main, .15),
};

type ToolBoxItemProps = {
  children: any,
  url?: string,
  icon?: string,
  openOutside: boolean,
};

const ToolBoxItemContent: FC<ToolBoxItemProps> = ({ children, icon }) =>
  <Grid sx={boxStyle}>
    <Box sx={boxContentStyle}>
      {icon &&
        <Box sx={iconStyle}>
          <Icon icon={icon} size={55} />
        </Box>
      }
      <Typography variant="h2" sx={boxLabelStyle}>{children}</Typography>
    </Box>
  </Grid>

const ToolBoxItem: FC<ToolBoxItemProps> = (props) => {
  const handleClickLink = (event: any) => {
    event.stopPropagation();
  };

  return (
    <a
      href={props.url}
      style={{ textDecoration: 'none' }}
      target={props.openOutside ? '_blank' : '_self'}
      rel="noreferrer"
      onClick={handleClickLink}
    >
      <ToolBoxItemContent {...props}>{props.children}</ToolBoxItemContent>
    </a>
  );
}

const ToolBox: FC = () => {
  const dispatch = useDispatch();
  const { data } = Services.useAll();
  const [toolbox, setToolbox] = useState<Toolbox>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchToolbox = useCallback(async () => {
    const response = await data.fetch(requests.getToolbox());
    setIsLoading(false);
    if (!response) return;
    setToolbox(response);
  }, [data]);

  useEffect(() => {
    fetchToolbox();
  }, [fetchToolbox]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    }
  });

  const close = () => {
    dispatch(setIsToolbox(false));
  };

  return (
    <Box sx={bgImgStyle} onClick={close}>
      <PageContainer>
        <Stack direction='row' sx={[containerStyle, { justifyContent: isLoading ? 'center' : 'flex-start' }]}>
          {isLoading ?
            <LoadingView />
            : toolbox.map(group =>
              <Box key={group.id} sx={groupStyle}>
                <Stack direction='row' justifyContent='space-between'>
                  <Typography sx={titleStyle}>
                    {group.label}
                  </Typography>
                  {group.button &&
                    <Button sx={buttonStyle} component={Link} to={group.button.url}>
                      {group.button.label}
                    </Button>
                  }
                </Stack>
                <Stack direction='row' sx={{ width: `${(138 + 12) * group.columns}px` }} flexWrap='wrap'>
                  {group.links.map(link =>
                    <ToolBoxItem key={link.id} icon={link.icon} url={link.url} openOutside={link.openOutside}>
                      {link.label}
                    </ToolBoxItem>
                  )}
                </Stack>
              </Box>
            )}
        </Stack>
      </PageContainer>
    </Box>
  );
};

export default ToolBox;
