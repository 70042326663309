// import {
//   Absences,
//   Agenda,
//   AgendaForm,
//   Business,
//   Chat,
//   Classifieds,
//   ClassifiedsDetail,
//   ClassifiedsForm,
//   HomePage,
//   Informations,
//   MyClassifieds,
//   News,
//   NewsDetail,
//   Notes,
//   Student,
//   StudentSettings,
//   SuggestionBox,
// } from './pages';

import { AppCommonComponent, ChatShortcuts } from './common';
import { ComponentType, FC, Suspense, lazy } from 'react';
import { DefaultRoute, Routes } from '../core/router';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { AppTopBar } from './common/appTopBar';
import { SearchContainer as Search } from './pages/search';
import StudentAddJob from './pages/student/StudentAddJob';

const Absences = lazy(() => import("./pages/absences"));
const Agenda = lazy(() => import("./pages/agenda"));
const AgendaForm = lazy(() => import("./pages/agenda/indexForm"));
const Business = lazy(() => import("./pages/business"));
const Chat = lazy(() => import("./pages/chat"));
// const Classifieds = lazy(() => import("./pages/classifieds"));
// const ClassifiedsDetail = lazy(() => import("./pages/classifieds/indexDetails"));
// const ClassifiedsForm = lazy(() => import("./pages/classifieds/indexForm"));
// const MyClassifieds = lazy(() => import("./pages/classifieds/indexMyClassified"));
const HomePage = lazy(() => import("./pages/homepage"));
const Informations = lazy(() => import("./pages/informations"));
const News = lazy(() => import("./pages/news"));
const NewsDetail = lazy(() => import("./pages/news/indexDetail"));
const Notes = lazy(() => import("./pages/notes"));
const Student = lazy(() => import("./pages/student"));
const StudentSettings = lazy(() => import("./pages/student/indexSettings"));
const SuggestionBox = lazy(() => import("./pages/suggestion"));
const PartnerUniversities = lazy(() => import("./pages/partnerUniversities"));
// const ContactForm = lazy(() => import("./pages/contactForm"));
const Alert = lazy(() => import("./pages/alert"));
interface PageDefinition {
  path: Routes;
  page: ComponentType;
  params?: string;
  exact?: boolean;
}

const getPath = (path: string, params?: string) =>
  params
    ? `${path}/${params}`
    : path;

const Body: FC = () =>
  <Router>
    <AppTopBar />
    <Switch>
      <Suspense fallback={<div>Loading...</div>}>
        {
          pages.map(({ path, page: Component, params, exact }, i) => (
            <Route
              key={i}
              exact={exact}
              path={getPath(path, params)}
              component={Component}
            />
          ))
        }
      </Suspense>
      <Route path="/*">
        <Redirect to={DefaultRoute} />
      </Route>
    </Switch>
    <ChatShortcuts />
    <AppCommonComponent />
  </Router>

export default Body;

const pages = new Array<PageDefinition>(
  { path: Routes.AgendaAdd, page: AgendaForm },
  { path: Routes.AgendaEdit, page: AgendaForm, params: ':id?' },
  { path: Routes.Agenda, page: Agenda, exact: true },
  { path: Routes.Alert, page: Alert, params: ':id' },
  { path: Routes.Notes, page: Notes },
  { path: Routes.Absences, page: Absences },
  // { path: Routes.ClassifiedsDetail, page: ClassifiedsDetail, params: ':id' },
  // { path: Routes.MyClassifieds, page: MyClassifieds, exact: true },
  { path: Routes.News, page: News },
  { path: Routes.NewsDetail, page: NewsDetail, params: ':id' },
  // { path: Routes.ClassifiedsEdit, page: ClassifiedsForm, params: ':id' },
  // { path: Routes.ClassifiedsAdd, page: ClassifiedsForm },
  // { path: Routes.Classifieds, page: Classifieds },
  { path: Routes.Suggestion, page: SuggestionBox },
  { path: Routes.Business, page: Business, params: ':id' },
  { path: Routes.StudentAddJob, page: StudentAddJob },
  { path: Routes.StudentSettings, page: StudentSettings },
  { path: Routes.Student, page: Student, exact: true, params: ':id?' },
  { path: Routes.Chat, page: Chat },
  { path: Routes.Search, page: Search },
  { path: Routes.Informations, page: Informations },
  { path: Routes.HomePage, page: HomePage, exact: true },
  { path: Routes.PartnerUniversities, page: PartnerUniversities, exact: true },
  { path: Routes.ContactForm, page: Business, params: ':id' }
)
  ;
