import { alpha, SxProps, Theme } from '@mui/system';
import { Box, Stack, TextField, Chip, Typography } from '@mui/material';
import { FC, useState } from 'react';

const searchFieldContainerStyle: SxProps<Theme> = {
    padding: 0.75,
    borderRadius: '8px',
    '& .MuiFormControl-root': {
        marginBottom: 1,
    },
};

const searchFieldActivatedStyle: SxProps<Theme> = {
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.25),
};

const searchFieldStyle: SxProps<Theme> = {
    '& .MuiOutlinedInput-input': {
        backgroundColor: (theme) => theme.palette.primary.contrastText,
    },
};

const searchFieldDisabledStyle: SxProps<Theme> = {
    '& .MuiOutlinedInput-input': {
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
    },
};

const chipStyle: SxProps<Theme> = {
    color: (theme) => theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 13,
    justifyContent: 'flex-start',
    height: 'auto',
    paddingX: 1,
    paddingY: 1.5,
    marginBottom: 0.5,
    borderRadius: '5px',
    borderColor: (theme) => theme.palette.primary.main,
};

const chipSelectedStyle: SxProps<Theme> = {
    color: (theme) => theme.palette.primary.contrastText,
    borderColor: (theme) => theme.palette.primary.contrastText,
};

const noResultStyle: SxProps<Theme> = {
    fontWeight: 500,
    textAlign: 'center',
    marginY: 2,
};

type TextFieldSearchProps = {
  label: string,
  fieldName: string,
  setValue: Function,
  value: number | null,
  fetchList: Function,
  list: any[],
  setList: Function,
  isRequired?: boolean,
  isFocus?: boolean,
  isDisabled?: boolean,
}

const TextFieldSearch: FC<TextFieldSearchProps> = ({
  label,
  fieldName,
  setValue,
  value,
  fetchList,
  list,
  setList,
  isRequired,
  isFocus,
  isDisabled
}) => {
  const [search, setSearch] = useState<string>('');
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const handleChange = async(newSearch: string) => {
    // reset
    setList([]);
    setValue(fieldName, undefined);

    setSearch(newSearch);
    if(newSearch.length > 0){
      setIsFetching(true);
      await fetchList(newSearch);
      setIsFetching(false);
    }
  };
  const handleSelect = (id: number) => {
    const selected = list.find((item) => item.id === id);
    setSearch(selected?.label || '');
    setValue(fieldName, id);
  };

  return (
    <Box
      sx={() => ({ ...searchFieldContainerStyle,
        ...(isFocus && search.length > 0) ? searchFieldActivatedStyle : {}
      })}
    >
      <TextField
        label={label}
        defaultValue={''}
        value={search}
        onChange={(event) => handleChange(event.target.value)}
        fullWidth
        required={isRequired}
        disabled={isDisabled}
        sx={[searchFieldStyle, isDisabled ? searchFieldDisabledStyle : {}]}
      />
      {isFocus &&
        <Stack>
          {list.map((item: any) =>
            <Chip
              key={item.id}
              sx={[chipStyle, value === item.id ? chipSelectedStyle : {}]}
              variant='outlined'
              onClick={() => handleSelect(item.id)}
              label={item.label}
            />
          )}
          {(!isFetching && search.length > 0 && list.length === 0) &&
            <Typography sx={noResultStyle}>Aucun résultat</Typography>
          }
        </Stack>
      }
    </Box>
  );
};

export default TextFieldSearch;
