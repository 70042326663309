import { CustomTypeOptions, initReactI18next } from "react-i18next";

import { IndexedBy } from "./IndexedBy";
import { SecondaryResources } from "../i18n/utils";
import en from '../i18n/en';
import fr from '../i18n/fr';
import { getPreferredLanguage } from "./utils";
import i18n from "i18next";

type Resources = CustomTypeOptions['resources'];
type Languages = typeof languages[number];

const languages = ['fr', 'en'] as const;

const resources: IndexedBy<Languages, Resources | SecondaryResources> = {
  fr,
  en,
};

const lng = getPreferredLanguage();

i18n
  .use(initReactI18next)
  .init({
    ns: Object.keys(fr),
    defaultNS: 'common',
    resources,
    lng,
    fallbackLng: 'fr',
    simplifyPluralSuffix: true,
    compatibilityJSON: 'v3',
  });

export default i18n;

export function initializeI18n(language: string) {
  i18n.changeLanguage(language);
}