import type { SxProps, Theme } from '@mui/system';

import { Box, useMediaQuery, useTheme } from '@mui/material';

export const ChatBubbleSize = 64;
export const ChatMargin = 160;
export const ChatWidth = ChatMargin * 2 + ChatBubbleSize;

const containerStyle: SxProps<Theme> =  {
    height: '100%',
    width: `${ChatWidth}px`,
    paddingX: `${ChatMargin}px`,
  };

const ChatShortcuts = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  if (matches) {
    return null;
  }

  return (
    <Box sx={containerStyle}>
    </Box>
  );
};

export default ChatShortcuts;
