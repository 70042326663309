import { Box, Button, SxProps, TextField, Theme, alpha } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';

import CommentsAvatar from './CommentsAvatar';
import Services from 'utilities/Services';

const containerStyle: SxProps<Theme> = {
  position: 'relative',
};

const contentFieldStyle: SxProps<Theme> = {
  marginBottom: 2.5,
  '& .MuiInputBase-root': {
    padding: 0,
    fontSize: 12,
    fontWeight: 400,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme => `${alpha(theme.palette.primary.contrastText, .5)} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme => `${alpha(theme.palette.primary.contrastText, .5)} !important`,
    },
  },
  '& .MuiInputBase-input': {
    paddingY: '14px !important',
    paddingLeft: '48px !important',
    color: theme => theme.palette.primary.contrastText,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '15px',
    borderColor: theme => alpha(theme.palette.primary.contrastText, .45),
  },
};

const buttonStyle: SxProps<Theme> = {
  fontSize: 16,
  paddingY: 1,
  paddingX: 2,
  background: 'rgb(59, 130, 246)',
  borderRadius: '8px',
  color: theme => theme.palette.primary.contrastText,

  '&:hover:enabled': {
    cursor: 'pointer',
    background: 'rgb(37, 99, 235)',
  },
  '&:disabled': {
    opacity: .7,
    cursor: 'default',
  },
};

const avatarContainerStyle: SxProps<Theme> = {
  position: 'absolute',
  top: 1,
  left: -22,
};

const cancelButtonStyle: SxProps<Theme> = {
  marginLeft: 1,
};

type CommentFormProps = {
  handleSubmit: (content: string, parentId?: string | null) => void;
  hasCancelButton?: boolean;
  handleCancel?: () => void;
  initialText?: string;
  id?: any;
};

const CommentForm: FC<CommentFormProps> = ({
  handleSubmit,
  hasCancelButton = false,
  handleCancel,
  initialText = '',
}) => {
  const { t } = useTranslation<Namespace>('common');
  const { whoami } = Services.useAll();
  const me = whoami.useMe();
  const [content, setContent] = useState(initialText);

  const onSubmit = (event: any) => {
    event.preventDefault();
    handleSubmit(content, null);
    setContent('');
  };

  const _handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      onSubmit(event);
    }
  };

  return (
    <Box sx={containerStyle}>
      <form onSubmit={onSubmit}>
        <TextField
          fullWidth
          multiline
          rows={1}
          placeholder={t('write comment')}
          sx={contentFieldStyle}
          value={content}
          onKeyDown={_handleKeyDown}
          onChange={e => setContent(e.target.value)}
        />
        <Box sx={avatarContainerStyle}>
          <CommentsAvatar src={me.photo || ''} />
        </Box>
        {hasCancelButton && (
          <Button
            sx={[buttonStyle, cancelButtonStyle]}
            onClick={handleCancel}
          >
            {t('cancel')}
          </Button>
        )}
      </form>
    </Box>
  );
};

export default CommentForm;
