import {
  AppBar,
  Box,
  Container,
  Skeleton,
  Toolbar,
  useScrollTrigger,
  useTheme,
  Typography
} from '@mui/material';
import { Routes } from '../../../core/router';
import { ReactElement, cloneElement } from 'react';
import type { SxProps, Theme } from '@mui/system';

import AppTopBarMenu from './AppTopBarMenu';
import LanguageSelector from './LanguageSelector';
import ChatNotifications from './ChatNotificationsContainer';
import Services from 'utilities/Services';
import StudentAvatar from '../../pages/student/components/StudentAvatar';
import themeVariable from '../../theme';

export const APPBAR_HEIGHT = 78;

const containerStyle: SxProps<Theme> = {
  position: 'relative',
  height: APPBAR_HEIGHT,
  paddingLeft: 4,
  backgroundColor: 'transparent',
  width: '100%',
  minWidth: '100%',
  marginLeft: 0,
  marginRight: 0,
  [themeVariable.breakpoints.up('xl')]: {
    marginLeft: 'auto',
    paddingRight: 4,
  },
};

const headerTopStyle: SxProps<Theme> = {
  background: theme => theme.palette.primary.main,
  '&.MuiPaper-elevation0': {
    background: 'transparent',
  }
};

const userStyle: SxProps<Theme> = {
  display: 'flex',
  height: APPBAR_HEIGHT,
  alignItems: 'center',
  paddingRight: '50px',
  marginLeft: '100px',
  [themeVariable.breakpoints.down('xl')]: {
    marginLeft: '75px',
  },
  [themeVariable.breakpoints.down('xl')]: {
    marginLeft: '50px',
  },
  [themeVariable.breakpoints.down('lg')]: {
    marginLeft: '20px',
  },
  [themeVariable.breakpoints.down('md')]: {
    marginLeft: '15px',
  },

  [themeVariable.breakpoints.down(1240)]: {
    position: 'absolute',
    top: '0',
    right: '20px',
  },
};

const chatStyle: SxProps<Theme> = {
  marginLeft: 4,
};

interface ElevationScrollProps {
  children: ReactElement;
}

function ElevationScroll(props: ElevationScrollProps) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const AppTopBar = () => {
  const themeVariable = useTheme();
  const { chat, whoami } = Services.useAll();
  const isReadyChat = chat.useIsReady();
  const me = whoami.useMe();

  return (
    <>
      <ElevationScroll>
        <AppBar sx={headerTopStyle}>
          <Container maxWidth="lg" sx={containerStyle} disableGutters>
            <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>

              <AppTopBarMenu />
              { process.env.REACT_APP_ENVIRONNEMENT !== "production" &&
                    <Typography variant="h3" color="red">{process.env.REACT_APP_ENVIRONNEMENT}</Typography>
                    }

              {me && (
                <Box sx={userStyle}>
                  <StudentAvatar
                    image={me.photo}
                    link={Routes.Student}
                    iconBgColor={themeVariable.palette.secondary.main}
                  />

                  <Box sx={{}}>
                    <LanguageSelector />
                  </Box>

                  {!isReadyChat && (
                    <Box >
                      <Skeleton variant="circular" width={55} height={55} />
                    </Box>
                  )}

                  {isReadyChat &&
                    <Box sx={chatStyle} style={{ marginLeft: 0 }}>
                      <ChatNotifications />
                    </Box>
                  }
                </Box>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Toolbar sx={containerStyle} disableGutters />
    </>
  );
};

export default AppTopBar;
