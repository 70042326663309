import { Box, IconButton, Link as MuiLink, Stack, Typography } from '@mui/material';
import { FC, ReactChild, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { SxProps, Theme } from '@mui/system';

import { APPBAR_HEIGHT } from '../../../common/appTopBar/AppTopBar';
import CommentsAvatar from '../../../common/comments/CommentsAvatar';
import Environment from '../../../../utilities/Environment';
import { Icon } from '../../../../core/utils';
import { Link } from 'react-router-dom';
import LoadingView from 'components/common/LoadingView';
import { PageContainer } from '../../../common';
import { Routes } from '../../../../core/router';
import { SearchTypes } from '../steps/Step1';

const containerStyle: SxProps<Theme> = {
  backgroundColor: (theme) => theme.palette.primary.main,
};

const backContainerStyle: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  height: 59,
  backgroundColor: (theme) => theme.palette.primary.dark,
  paddingLeft: 6,
  marginTop: 4,
  marginBottom: 3,
  svg: {
    marginRight: 7,
    transform: 'rotate(90deg)',
  },
};

const backContainerFixedStyle: SxProps<Theme> = {
  position: 'sticky',
  zIndex: 10,
  top: '64px', // Assuming APPBAR_HEIGHT is a constant representing the height of the app bar
};

const backButtonStyle: SxProps<Theme> = {
  flexDirection: 'row',
  alignItems: 'center',
  cursor: 'pointer',
};

const backTitleStyle: SxProps<Theme> = {
  fontSize: 12,
  fontWeight: 700,
};

const titleStyle: SxProps<Theme> = {
  fontSize: 25,
  fontWeight: 600,
  marginBottom: 4,
};

const resultsStyle: SxProps<Theme> = {
  paddingLeft: 14,
  paddingBottom: 4,
  width: 730,
};

const resultStyle: SxProps<Theme> = {
  marginBottom: '0.5px',
  '& a:first-child': {
    display: 'block',
    width: '100%',
    flexGrow: 1,
  },
};

const resultMainStyle: SxProps<Theme> = {
  flexGrow: 1,
  height: 47,
  marginRight: 1.5,
  paddingLeft: '6px',
  paddingRight: 2,
  alignItems: 'center',
  color: (theme) => theme.palette.primary.contrastText,
  border: '1px solid',
  borderColor: (theme) => theme.palette.primary.contrastText,
  borderRadius: '27px',
};

const resultNameStyle: SxProps<Theme> = {
  flexGrow: 1,
  fontSize: 9,
  fontWeight: 600,
  marginLeft: 2,
  color: (theme) => theme.palette.primary.contrastText,
  textTransform: 'uppercase',
};

const resultButtonStyle: SxProps<Theme> = {
  height: 46,
  width: 46,
  border: '1px solid',
  color: (theme) => theme.palette.primary.contrastText,
  borderColor: (theme) => theme.palette.primary.contrastText,
  background: 'transparent',
};

export type Result = {
  id: number | string,
  firstname: string,
  lastname: string,
  photo: string | null,
  email: string,
}

type ResultsProps = {
  results: Result[] | null,
  searchType: SearchTypes['value'],
  isLoading: boolean,
};

const Results: FC<ResultsProps> = ({ results, searchType, isLoading }) => {
  const { t } = useTranslation<Namespace>('search');
  const backBarRef = useRef<HTMLDivElement>();
  const [isFixedBar, setIsFixedBar] = useState<boolean>(false);

  useEffect(() => {
    if (results) {
      backBarRef.current?.scrollTo({
        top: backBarRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [results]);

  const onClickBack = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleScroll = useCallback(() => {
    if (!backBarRef.current) return;
    const { y } = backBarRef.current?.getBoundingClientRect();
    const isFixed = y <= APPBAR_HEIGHT;
    setIsFixedBar(isFixed);
  }, [])

  useLayoutEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    }
  });

  return isLoading
    ? <LoadingView text={t('search in progress')} />
    : (
      <Box sx={containerStyle}>
        <Stack
          component="div" // Add the missing component prop
          ref={backBarRef as React.RefObject<HTMLDivElement>} // Fix the type mismatch by using RefObject
          sx={[
            backContainerStyle,
            isFixedBar ? backContainerFixedStyle : {},
          ]}
        >
          <Stack sx={backButtonStyle} onClick={onClickBack}>
            <Icon icon='navigation_arrow-left' size={26} />
            <Typography variant='h2' sx={backTitleStyle}>
              {t('back to search')}
            </Typography>
          </Stack>
        </Stack>

        <PageContainer>
          <Box sx={resultsStyle}>
            <Typography variant='h2' sx={titleStyle}>
              {t('results')}
            </Typography>

            {results?.map((result) =>
              <Stack key={result.id} direction='row' sx={resultStyle}>
                <ConditionalLink searchType={searchType} id={result.id as number}>
                  <Stack direction='row' sx={resultMainStyle}>
                    <CommentsAvatar src={result.photo || ''} size={35} />
                    <Typography sx={resultNameStyle}>
                      {result.firstname} {result.lastname}
                    </Typography>
                    <Icon icon='navigation_user' size={25} />
                  </Stack>
                </ConditionalLink>
                {searchType === 'student'
                  ? (
                    <Link
                      to={{
                        pathname: Routes.Chat,
                        state: { userId: result.id }
                      }}
                    >
                      <IconButton sx={resultButtonStyle}>
                        <Icon icon='navigation_chat' size={22} />
                      </IconButton>
                    </Link>
                  ) : (
                    <MuiLink href={`${Environment.mail_url}deeplink/compose?to=${result.email}`} target='_blank' rel="noreferrer">
                      <IconButton sx={resultButtonStyle}>
                        <Icon icon='navigation_email' size={22} />
                      </IconButton>
                    </MuiLink>
                  )
                }
              </Stack>
            )}
            {!isLoading && results?.length === 0 &&
              <Typography>{t('no result')}</Typography>
            }
          </Box>
        </PageContainer>
      </Box>
    );
};

export default Results;

type ConditionalLinkProps = {
  searchType: SearchTypes['value'],
  id: number,
  children: ReactChild,
};

const ConditionalLink: FC<ConditionalLinkProps> = ({ searchType, id, children }) =>
  <>
    {searchType === 'student'
      ? (
        <Link to={`${Routes.Student}/${id}`}>
          {children}
        </Link>
      ) : children
    }
  </>