import { ApiService, RequestStore } from '@ward/library';
import networkClient, { FetchError, ServerError } from '../networkClient';

import { Api } from 'core/api';
import ChatBotService from 'services/ChatBotService';
import ChatService from 'library/ChatService';
import Environment from '../Environment';
import RemoteConfiguration from 'services/RemoteConfigurationService';
import { ServiceList } from '.';
import WhoAmIService from 'library/WhoAmIService';
import authentication from 'core/api/authentication';
import { chatBotConfig } from 'services/chatBotConfig';
import MatomoReactService from 'services/MatomoReactService';

export default async function loadServices(): Promise<ServiceList> {
  try {
    // Api service
    const api = new ApiService(
      {
        url: Environment.api_url,
        store: new RequestStore(),
        networkClient,
        debug: true,
        defaultFetchOptions: {forceFetching: true},
      },
      { authentication },
    );

    // RemoteConfiguration service
    const remoteConfiguration = await new RemoteConfiguration({
      api: Api,
    }).init();

    // WhoAmI service
    const whoami = await new WhoAmIService({
      authentication,
      api: Api,
    }).init();

    // MatomoReact service
    const matomo = await new MatomoReactService( whoami ).init();

    // ChatBot service
    const chatBot = new ChatBotService({
      ...chatBotConfig,
      apiService: api,
    }).init();

    // Chat service
    const onError = (path: string) =>
    new FetchError(path);

    const onServerError = (method: string, path: string, httpCode: number, body: any) =>
    new ServerError(method, path, httpCode, body);

    const chat = await new ChatService(
      {
        chatApiUrl: Environment.chat_api_url,
        acsUrl: Environment.chat_acs_url,
      },
      {
        api: Api,
        authentication: authentication,
        whoami,
        onError,
        onServerError,
      },
    ).init();

    return {
      data: api,
      remoteConfiguration,
      whoami,
      matomo,
      chat,
      chatBot,
    };
  } catch (err) {
    console.error(err);
    throw err;
  }
}
