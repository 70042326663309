import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  value: {
    categories: [],
  },
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state: any, action: any) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setCategories }: any =
  categoriesSlice.actions;

export const getCategories = (state: any) => state.categories.value.categories;

export default categoriesSlice.reducer;
