import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

export const conversationsSlice = createSlice({
  name: 'conversationsRedux',
  initialState,
  reducers: {
    setReduxConversations: (state: any, action: any) => {
      state.value = action.payload;
    },
  },
});

export const { setReduxConversations }: any = conversationsSlice.actions;

export const getReduxConversations = (state: any) => state.conversationsRedux.value;

export default conversationsSlice.reducer;
