import { Button, Stack, Typography, alpha } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

import { FC } from 'react';
import { Icon } from 'core/utils';
import { t } from 'i18next';

const containerStyle: SxProps<Theme> = {
  flexDirection: 'row',
  justifyContent: 'flex-start',
};

const choiceStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  width: 201,
  height: 187,
  marginRight: 2,
  alignItems: 'center',
  justifyContent: 'center',
  color: theme => theme.palette.primary.contrastText,
  background: '#05156B',
  border: '1px solid',
  borderColor: '#6678D8',
  borderRadius: 2,
  textTransform: 'none',
  '&:hover': {
    background: '#6678D8',
  },
  svg: {
    color: theme => alpha(theme.palette.primary.contrastText, .3),
  },
};

const choiceSelectedStyle: SxProps<Theme> = {
  background: '#6678D8',
};

const choiceLabelStyle: SxProps<Theme> = {
  fontSize: 14,
  fontWeight: 600,
  marginTop: 3,
};

export type SearchTypes = {
  id: number,
  value: 'student' | 'staff' | 'teacher' | 'alumni',
  label: string,
  icon: string,
};

export const SEARCH_TYPES: SearchTypes[] = [
  {
    id: 0,
    value: 'student',
    label: t('search:student'),
    icon: 'search_students',
  },
  {
    id: 1,
    value: 'staff',
    label: t('search:staff'),
    icon: 'search_staff',
  },
  {
    id: 2,
    value: 'teacher',
    label: t('search:teacher'),
    icon: 'search_teachers',
  },
  {
    id: 3,
    value: 'alumni',
    label: t('search:alumni'),
    icon: 'search_alumni',
  },
];

type Step1Props = {
  selected?: SearchTypes['value'],
  setSelected: Function,
};

const Step1: FC<Step1Props> = ({ selected, setSelected }) =>
  <Stack sx={containerStyle}>
    {SEARCH_TYPES.map((choice) =>
      <Button
        key={choice.id}
        disableRipple
        disableFocusRipple
        onClick={() => setSelected(choice.value)}
        sx={[choiceStyle, selected === choice.value ? choiceSelectedStyle : {}]}
      >
        <Icon icon={choice.icon} size={70} />
        <Typography sx={choiceLabelStyle}>
          {choice.label}
        </Typography>
      </Button>
    )}
  </Stack>

export default Step1;