import { Box, Button, Stack, SxProps, Theme, Typography, alpha } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Namespace } from "i18next";

import { AbuseReasonsContentType } from "@ward/library/dist/Endpoints/abuse-reasons";
import { CommentAnswerModel } from "@ward/library/dist/Models/CommentAnswer";
import CommentForm from "./CommentForm";
import CommentsAvatar from "./CommentsAvatar";
import { CommentsEnpoints } from "./Comments";
import { Link } from "react-router-dom";
import ModalReport from "../modal/ModalReport";
import { Model } from "@ward/library";
import { Routes } from "../../../core/router";
import Services from "utilities/Services";
import requests from "./requests";

const containerStyle: SxProps<Theme> = {
  position: 'relative',
  marginBottom: 1.5,
  marginTop: 3,
  '&:first-child': {
    marginTop: .5,
  },
};

const avatarContainerStyle: SxProps<Theme> = {
  position: 'absolute',
  zIndex: 999,
  top: 8,
  left: -22,
};

const contentStyle: SxProps<Theme> = {
  position: 'relative',
  width: '100%',
  paddingTop: 2,
  paddingRight: 3,
  paddingBottom: 4,
  paddingLeft: 5,
  backgroundColor: '#243695',
  borderRadius: '15px',
};

const authorStyle: SxProps<Theme> = {
  fontSize: 12,
  fontWeight: 700,
};

const textStyle: SxProps<Theme> = {
  fontSize: 12,
  fontWeight: 400,
  marginTop: 2,
};

const actionButtonStyle: SxProps<Theme> = {
  height: 22,
  paddingY: 0,
  fontSize: 8,
  fontWeight: 700,
  textTransform: 'none',
  color: theme => theme.palette.primary.contrastText,
  borderColor: theme => alpha(theme.palette.primary.contrastText, .14),
  borderRadius: '15px',
};

const answersStyle: SxProps<Theme> = {
  paddingLeft: 6,
  marginTop: 2,
  marginBottom: 7.5,
  borderLeft: '1px solid',
  borderColor: theme => alpha(theme.palette.primary.contrastText, .2),
};

const reportContainerStyle: SxProps<Theme> = {
  position: 'absolute',
  right: 20,
  bottom: 8,
  fontSize: 0,
};

type CommentProps = {
  id: number,
  comment: Model.Comment,
  setActiveComment: Function,
  activeComment: {
    id: any,
    type: 'editing' | 'replying',
  } | null,
  parentId?: any,
  endpoints: CommentsEnpoints,
  contentType: AbuseReasonsContentType,
};

const Comment: FC<CommentProps> = ({
  id,
  comment,
  setActiveComment,
  activeComment,
  parentId = null,
  endpoints,
  contentType,
}) => {
  const { t } = useTranslation<Namespace>('common');
  const { data } = Services.useAll();
  const [answers, setAnswers] = useState<Model.CommentAnswer[]>(comment.last_answers || []);

  const isEditing =
    activeComment?.id === comment.id &&
    activeComment.type === "editing";
  const isReplying =
    activeComment?.id === comment.id &&
    activeComment.type === "replying";
  const canReply = !parentId;
  const replyId = parentId || comment.id;

  const fetchAnswers = useCallback(async () => {
    const response = await data.fetch(
      requests.getAnswers(endpoints.getCommentAnswers, contentType, id, comment.id)
    ) as CommentAnswerModel[];
    if (!response) return;
    setAnswers(response);
  }, [id, comment.id, contentType, data, endpoints.getCommentAnswers]);

  useEffect(() => {
    fetchAnswers();
  }, [comment, fetchAnswers]);

  const submitAnswer = async (content: string) => {
    const response = await data.fetch(
      requests.addAnswer(endpoints.addCommentAnswers, contentType, id, content, replyId)
    );
    if (!response) return;
    fetchAnswers();
  };

  const submitCommentReport = async (reason: string) => {
    const submitCommentReportRequest = requests.reportComment(
      endpoints.reportComment, contentType, id, comment.id, reason
    );
    const submitAnswerReportRequest = requests.reportAnswer(
      endpoints.reportCommentAnswer, contentType, id, parentId, comment.id, reason
    );
    const request = parentId ? submitAnswerReportRequest : submitCommentReportRequest
    await data.fetch(request);
  };

  return (
    <Box sx={containerStyle}>
      {comment.author.photo &&
        <Box sx={avatarContainerStyle}>
          <Link to={`${Routes.Student}/${comment.author.id}`}>
            <CommentsAvatar src={comment.author.photo} />
          </Link>
        </Box>
      }
      <Box sx={contentStyle}>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Typography sx={authorStyle}>
            {comment.author.firstname}
          </Typography>
          <Stack direction='row'>
            {canReply && (
              <Button
                sx={actionButtonStyle}
                variant='outlined'
                onClick={() =>
                  setActiveComment({ id: comment.id, type: "replying" })
                }
              >
                {t('reply to comment')}
              </Button>
            )}
          </Stack>
        </Stack>
        {!isEditing &&
          <Box sx={textStyle}>
            {comment.content}
          </Box>
        }
        <Box sx={reportContainerStyle}>
          <ModalReport contentType='ad-comment' submitReport={submitCommentReport} iconOnly />
        </Box>
      </Box>
      {(answers.length > 0 || isReplying) &&
        <Box sx={answersStyle}>
          {answers.map((answer: any) => (
            <Comment
              key={answer.id}
              id={id}
              comment={answer}
              setActiveComment={setActiveComment}
              activeComment={activeComment}
              parentId={comment.id}
              endpoints={endpoints}
              contentType={contentType}
            />
          ))}

          {isReplying && (
            <CommentForm
              handleSubmit={(content) => submitAnswer(content)}
            />
          )}
        </Box>
      }
    </Box>
  );
};

export default Comment;