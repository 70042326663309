import { Box, Button, Chip, Typography } from '@mui/material';
import { Model, Reference } from '@ward/library';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { SxProps, Theme, alpha } from '@mui/system';
import { Dispatch, SetStateAction, useState } from 'react';
import { Icon } from '../../../../core/utils';

import Services from 'utilities/Services';
import requests from '../requests';

const chipStyle: SxProps<Theme> = {
  display: 'flex',
  paddingLeft: 2,
  paddingRight: 1,
  justifyContent: 'left',
  color: '#24358E',
  borderColor: '#24358E',
  borderRadius: 1.5,
  fontWeight: 600,
  fontSize: 12,
  height: 37,
  marginRight: 1,
  marginBottom: 1 / 2,
  '& .MuiChip-label': {
    width: '100%',
  },
  '& .MuiChip-deleteIcon': {
    color: (theme) => alpha(theme.palette.primary.main, 0.3),
    '&:hover': {
      color: (theme) => alpha(theme.palette.primary.main, 0.2),
    },
  },
  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
  '&:hover': {
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.15),
  },
  '&:active': {
    boxShadow: 'none',
  },
  '& .MuiChip-icon': {
    marginRight: 1,
    order: 2,
    color: (theme) => theme.palette.primary.contrastText,
  },
};

const chipListIconStyle: SxProps<Theme> = {
  '&.MuiChip-icon': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 18px',
    height: 18,
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.3),
    borderRadius: '50%',
  },
};

const chipListSelectedStyle: SxProps<Theme> = {
  backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.24),
  cursor: 'default',
  '&:hover': {
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.24),
  },
};

const noResultStyle: SxProps<Theme> = {
  fontSize: 12,
  fontWeight: 400,
  marginTop: 2,
};

type CategoryItem = {
  item: Reference.Hobby;
  categoryLabel: string;
};

function StudentCategoryList({
  categoryLabel,
  category,
  updateItem,
}: {
  categoryLabel: string;
  category: Model.Hobby[] | Model.Assistance[];
  updateItem: Dispatch<SetStateAction<CategoryItem>>;
}): JSX.Element {

  const { t } = useTranslation<Namespace>('profile');
  const { data, remoteConfiguration, whoami } = Services.useAll();
  const me = whoami.useMe();
  const { assistance_suggestion_email } =
    remoteConfiguration.getConfiguration();
  const [search, setSearch] = useState<string>('');
  const suggestHobby = async () => {
    await data.fetch(requests.suggestHobby(search));
    setSearch('');
  };
  const isSelected = (
    id: number,
    list: Reference.Hobby[] | Reference.Assistance[],
  ) => !!list.find(item => item.id === id);

  const DisplayList = (list: Model.Hobby[] | Model.Assistance[]) => {
    const addStudentCategoryItem = (
      item: Reference.Hobby,
      categoryLabel: string,
    ) => {
      return updateItem({ item, categoryLabel });
    };

    return list.map((item: Model.Hobby | Model.Assistance) => {
      const label = item.label ? item.label : 'no label';
      if (categoryLabel === 'hobbies') {
        return (
          <Chip
            key={item.id}
            sx={() => ({ ...chipStyle, ...(isSelected(item.id, me.hobbies) ? chipListSelectedStyle : {}) })}
            label={label}
            onClick={() => addStudentCategoryItem(item, categoryLabel)}
            icon={
              !isSelected(item.id, me.hobbies) ? (
                <Box sx={chipListIconStyle}>
                  <Icon icon="navigation_plus" size={16} />
                </Box>
              ) : (
                <Typography>Ajouté !</Typography>
              )
            }
          />
        );
      } else if (categoryLabel === 'assistances') {
        return (
          <Chip
            key={item.id}
            sx={() => ({ ...chipStyle, ...(isSelected(item.id, me.hobbies) ? chipListSelectedStyle : {}) })}
            label={label}
            onClick={() => addStudentCategoryItem(item, categoryLabel)}
            icon={
              !isSelected(item.id, me.assistances) ? (
                <Box sx={chipListIconStyle}>
                  <Icon icon="navigation_plus" size={16} />
                </Box>
              ) : (
                <Typography>Ajouté !</Typography>
              )
            }
          />
        );
      } else {
        return <></>;
      }
    });
  };

  if (category.length === 0 && categoryLabel === 'hobbies') {
    return (
      <Box textAlign="center">
        <Typography>{t('suggestionNotAvailable')}</Typography>
        <Box marginTop={3}>
          <Button variant="contained" onClick={suggestHobby} disabled={true}>
            {t('suggest')}
          </Button>
        </Box>
      </Box>
    );
  } else if (category.length === 0 && categoryLabel === 'assistances') {
    return (
      <Box textAlign="center">
        <Typography sx={noResultStyle}>
          {t('searchNoResultAssistance')}
        </Typography>
        <Box marginTop={3}>
          <a href={`mailto:${assistance_suggestion_email}`}>
            <Icon icon="navigation_email" size={40} />
          </a>
        </Box>
      </Box>
    );
  } else {
    return <>{DisplayList(category)}</>;
  }
}

export default StudentCategoryList;
