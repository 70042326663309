import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  value: {
    newsCategories: [],
    newsSectionCategories: [],
    sites: [],
  },
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNewsCategories: (state: any, action: any) => {
      state.value = { ...state.value, ...action.payload };
    },
    setNewsSectionCategories: (state: any, action: any) => {
      state.value = { ...state.value, ...action.payload };
    },
    setSites: (state: any, action: any) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { setNewsCategories, setNewsSectionCategories, setSites }: any =
  newsSlice.actions;

export const getNewsCategories = (state: any) => state.news.value.newsCategories;

export const getNewsSectionCategories = (state: any) => state.news.value.newsSectionCategories;

export const getSites = (state: any) => state.news.value.sites;

export default newsSlice.reducer;
