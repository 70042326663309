// @ts-nocheck
import { ApiRequests, Endpoints, Model } from '@ward/library';
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { SxProps, Theme, alpha } from '@mui/system';
import { useCallback, useEffect, useState } from 'react';

import DatePickerComponent from '../../common/DatePickerComponent';
import FormContainer from '../../common/FormContainer';
import { PageContainer } from '../../common';
import { Routes } from '../../../core/router';
import Services from 'utilities/Services';
import TextFieldSearch from './components/TextFieldSearch';
import { t } from 'i18next';

const styles: SxProps<Theme> = {
  content: {
    paddingLeft: '8%',
    paddingRight: '10%',
    paddingTop: 7,
    paddingBottom: 6,
  },
  column: {
    paddingRight: 8,
  },
  title: {
    fontSize: 40,
    fontWeight: 500,
    textTransform: 'capitalize',
    maxWidth: 300,
  },
  submitButton: {
    marginTop: 5,
    paddingX: 14,
  },
  searchFieldContainer: {
    padding: .75,
    borderRadius: '8px',
    '& .MuiFormControl-root': {
      marginBottom: 1,
    },
  },
  searchFieldActivated: {
    backgroundColor: theme => alpha(theme.palette.primary.main, .25),
  },
  searchField: {
    '& .MuiOutlinedInput-input': {
      backgroundColor: theme => theme.palette.primary.contrastText,
    },
  },
  chip: {
    color: theme => theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 13,
    justifyContent: 'flex-start',
    height: 'auto',
    paddingX: 1,
    paddingY: 1.5,
    marginBottom: 1 / 2,
    borderRadius: '5px',
    borderColor: theme => theme.palette.primary.main,
  },
  chipSelected: {
    color: theme => theme.palette.primary.contrastText,
    borderColor: theme => theme.palette.primary.contrastText,
  },
};

type JobModelContract = 'internship' | 'sandwich-course' | 'permanent-contract' | 'temporary-contract' | 'entrepreneurship';

const mockContracts = [
  {
    id: 0,
    value: 'internship',
    label: t('profile:internship'),
  },
  {
    id: 1,
    value: 'sandwich-course',
    label: t('profile:sandwich_course'),
  },
  {
    id: 2,
    value: 'permanent-contract',
    label: t('profile:permanent_contract'),
  },
  {
    id: 3,
    value: 'temporary-contract',
    label: t('profile:temporary_contract'),
  },
  {
    id: 4,
    value: 'entrepreneurship',
    label: t('profile:entrepreneurship'),
  },
]

const StudentAddJob = () => {
  const { data } = Services.useAll();
  const [countries, setCountries] = useState<Model.Country[]>([]);
  const [cities, setCities] = useState<Model.City[]>([]);
  const [companies, setCompanies] = useState<Model.Company[]>([]);
  const [contracts, setContracts] = useState<any[]>([]);
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const { register, setValue, getValues, handleSubmit, control } = useForm();

  const fetchContracts = useCallback(() => {
    setContracts(mockContracts);
  }, [setContracts]);

  useEffect(() => {
    fetchContracts();
  }, [fetchContracts]);

  const fetchCountries = useCallback(async () => {
    const fetchCountriesRequest = ApiRequests.fromEndpoint(
      Endpoints.countries.getCountries,
      {
        pathParams: null,
        queryParams: null,
        data: null,
        files: null,
      },
    );
    const response = await data.fetch(fetchCountriesRequest);
    if (!response) return;
    setCountries(response);
  }, [data]);

  const fetchCities = useCallback(async (search: string) => {
    const fetchCitiesRequest = ApiRequests.fromEndpoint(
      Endpoints.countries.getCities,
      {
        pathParams: { country: getValues('country') },
        queryParams: { search, limit: 9999999 },
        data: null,
        files: null,
      },
    );
    const response = await data.fetch(fetchCitiesRequest);
    if (!response) return;
    setCities(response);
  }, [data, getValues]);

  const fetchCompanies = useCallback(async (search: string) => {
    const fetchCompaniesRequest = ApiRequests.fromEndpoint(
      Endpoints.companies.getCompanies,
      {
        pathParams: null,
        queryParams: { search },
        data: null,
        files: null,
      },
    );
    const response = await data.fetch(fetchCompaniesRequest);
    if (!response) return;
    setCompanies(response);
  }, [data]);

  const submitJob = async (_submitData: any) => {
    await data.fetch(updateStudentRequest);
  };

  const onSubmit = (submitData: any) => {
    const start = new Date(data.start_date).toISOString();
    const end = new Date(data.end_date).toISOString();
    const job = {
      start,
      end,
      company: submitData.company,
      contract: submitData.contract as JobModelContract,
      country: submitData.country,
      city: submitData.city,
    };
    submitJob(job);
  };

  const onError = (errors: any, e: any) => {
    console.log(errors, e);
  };

  useEffect(() => {
    register('start_date');
    register('end_date');
    register('company');
    register('country')
    register('city')
  }, [register]);

  return (
    <PageContainer>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <FormContainer
          backLabel={t('profile:back to the profile')}
          backLink={Routes.Student}
        >
          <Grid container sx={styles.content} rowSpacing={6} columnSpacing={8}>
            <Grid item xs={12}>
              <Typography variant='h1' sx={styles.title}>
                {t('new career')}
              </Typography>
            </Grid>
            <Grid item md={4} sx={styles.column}>
              <DatePickerComponent onChange={setValue} label='Début' />
              <DatePickerComponent onChange={setValue} label='Fin' />
              <Controller
                control={control}
                name='contract'
                render={({ field: { value } }) => (
                  <FormControl fullWidth sx={styles.field} required>
                    <InputLabel
                      sx={styles.borderSolid}
                    >
                      {t('profile:contract')}
                    </InputLabel>
                    <Select
                      label={t('profile:contract')}
                      sx={styles.select}
                      value={value || ''}
                      {...register('contract')}
                    >
                      {contracts.map((contract: any) =>
                        <MenuItem key={contract.id} value={contract.value}>{contract.label}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}
              />
              <Button
                sx={styles.submitButton}
                disableRipple
                disableFocusRipple
                type="submit"
                variant="contained"
              >
                {t('profile:publish')}
              </Button>
            </Grid>

            <Grid item md={4} sx={styles.column}>
              <Box
                onFocus={() => setFocusedField('company')}
              //onBlur={() => setFocusedField(null)}
              >
                <TextFieldSearch
                  label={t('profile:company')}
                  fieldName='company'
                  setValue={setValue}
                  value={getValues('company')}
                  fetchList={fetchCompanies}
                  list={companies}
                  setList={setCompanies}
                  isRequired={true}
                  isFocus={focusedField === 'company'}
                />
              </Box>
            </Grid>

            <Grid item md={4} sx={styles.column}>
              <Box
                onFocus={() => setFocusedField('country')}
              //onBlur={() => setFocusedField(null)}
              >
                <TextFieldSearch
                  label={t('profile:country')}
                  fieldName='country'
                  setValue={setValue}
                  value={getValues('country')}
                  fetchList={fetchCountries}
                  list={countries}
                  setList={setCountries}
                  isRequired={true}
                  isFocus={focusedField === 'country'}
                />
              </Box>
              <Box
                onFocus={() => setFocusedField('city')}
              //onBlur={() => setFocusedField(null)}
              >
                <TextFieldSearch
                  label={t('profile:city')}
                  fieldName='city'
                  setValue={setValue}
                  value={getValues('city')}
                  fetchList={fetchCities}
                  list={cities}
                  setList={setCities}
                  isRequired={false}
                  isFocus={focusedField === 'city'}
                  isDisabled={!getValues('country')}
                />
              </Box>
            </Grid>
          </Grid>
        </FormContainer>
      </form>
    </PageContainer>
  );
};

export default StudentAddJob;
