import {
  MicrosoftAuthenticationService,
  MicrosoftKeys,
  MicrosoftLoginAttempt,
  MockingAuthenticationService,
  MockingKeys,
  PersistantStore,
} from '@ward/library';

import Environment from '../../utilities/Environment';
import networkClient from '../../utilities/networkClient';

const getToken = async () => {
  const token = localStorage.getItem('ms-token');
  if (!token) return null;
  else return JSON.parse(token);
};

const keysStorageClient: PersistantStore<MicrosoftKeys> = {
  get: getToken,
  set: async (keys: any) => {
    localStorage.setItem('ms-token', JSON.stringify(keys));
  },
  remove: async () => {
    localStorage.removeItem('ms-token');
  },
};

const attemptStorageClient: PersistantStore<MicrosoftLoginAttempt> = {
  get: async () => {
    const attempt = localStorage.getItem('ms-attempt');
    if (!attempt) return null;
    else return JSON.parse(attempt);
  },
  set: async (keys: any) => {
    localStorage.setItem('ms-attempt', JSON.stringify(keys));
  },
  remove: async () => {
    localStorage.removeItem('ms-attempt');
  },
};

const mockStorageClient: PersistantStore<MockingKeys> = {
  get: getToken,
  set: async (keys: any) => {
    localStorage.setItem('ms-token', JSON.stringify(keys));
  },
  remove: async () => {
    localStorage.removeItem('ms-token');
  },
};

const authentication = Environment.useMock ?
  new MockingAuthenticationService({
    url: Environment.api_url,
    redirect_uri: Environment.mock_redirect,
    storageClient: mockStorageClient,
  })
  : new MicrosoftAuthenticationService({
    clientId: Environment.auth.client_id,
    redirectUri: window.location.origin + '/',
    scopes: Environment.auth.scopes,
    domainHint: Environment.auth.domain_hint,
    networkClient: networkClient,
    keysStorageClient: keysStorageClient,
    attemptStorageClient: attemptStorageClient,
  });

export default authentication;
