type Env = {
  api_url: string;
  auth: AuthEnv;
  useMock: boolean;
  mock_redirect: string;
  mail_url: string;
  chatbot_url: string;
  chatbot_api_script_name: string;
  chat_api_url: string;
  chat_acs_url: string;
  cms_url: string;
  matomo_url: string;
  matomo_id: string;
  justify_absence_url: string;
  declare_absence_url: string;
  absence_for_one_course: string;
  absence_for_several_courses: string;
  google_api_key: string;
  environnement: string;
};

type AuthEnv = {
  client_id: string;
  scopes: Array<string>;
  domain_hint: string;
};

const api_url =
  process.env.REACT_APP_USE_MOCK === 'true'
    ? process.env.REACT_APP_MOCK_URL
    : process.env.REACT_APP_API_URL;
if (typeof api_url !== 'string')
  throw new Error('Missing env REACT_APP_API_URL');

const client_id = process.env.REACT_APP_MSAL_CLIENT_ID;
if (typeof client_id !== 'string')
  throw new Error('Missing env REACT_APP_MSAL_CLIENT_ID');

const scopes = process.env.REACT_APP_MSAL_SCOPES;
if (typeof scopes !== 'string')
  throw new Error('Missing env REACT_APP_MSAL_SCOPES');

const domain_hint = process.env.REACT_APP_MSAL_DOMAIN_HINT;
if (typeof domain_hint !== 'string')
  throw new Error('Missing env REACT_APP_MSAL_DOMAIN_HINT');

const mail_url = process.env.REACT_APP_MAIL_URL;
if (typeof mail_url !== 'string')
  throw new Error('Missing env REACT_APP_MAIL_URL');

const chatbot_url = process.env.REACT_APP_CHATBOT_URL;
if (typeof chatbot_url !== 'string')
  throw new Error('Missing env REACT_APP_CHATBOT_URL');

const chatbot_api_script_name = process.env.REACT_APP_CHATBOT_API_SCRIPT_NAME;
if (typeof chatbot_api_script_name !== 'string')
  throw new Error('Missing env REACT_APP_CHATBOT_API_SCRIPT_NAME');

const chat_api_url = process.env.REACT_APP_CHAT_API_URL;
if (typeof chat_api_url !== 'string')
  throw new Error('Missing env REACT_APP_CHAT_API_URL');

const chat_acs_url = process.env.REACT_APP_AZURE_COMMUNICATION_SERVICES_URL;
if (typeof chat_acs_url !== 'string')
  throw new Error('Missing env REACT_APP_AZURE_COMMUNICATION_SERVICES_URL');

const cms_url = process.env.REACT_APP_CMS_URL;
if (typeof cms_url !== 'string')
  throw new Error('Missing env REACT_APP_CMS_URL');

const matomo_url = process.env.REACT_APP_MATOMO_URL;
if (typeof matomo_url !== 'string')
  throw new Error('Missing env REACT_APP_MATOMO_URL');

const matomo_id = process.env.REACT_APP_MATOMO_ID;
if (typeof matomo_id !== 'string')
  throw new Error('Missing env REACT_APP_MATOMO_ID');

const justify_absence_url = process.env.REACT_APP_JUSTIFY_ABSENCE_URL;
if (typeof justify_absence_url !== 'string')
  throw new Error('Missing env REACT_APP_JUSTIFY_ABSENCE_URL');

const declare_absence_url = process.env.REACT_APP_DECLADE_ABSENCE_URL;
if (typeof declare_absence_url !== 'string')
  throw new Error('Missing env REACT_APP_DECLADE_ABSENCE_URL');

const google_api_key = process.env.REACT_APP_GOOGLE_API_KEY;
if (typeof google_api_key !== 'string')
  throw new Error('Missing env REACT_APP_GOOGLE_API_KEY');

const environnement = process.env.REACT_APP_ENVIRONNEMENT;
if (typeof environnement !== 'string')
  throw new Error('Missing env REACT_APP_ENVIRONNEMENT');

const absence_for_one_course =
  process.env.REACT_APP_ABSENCES_FOR_ONE_COURSE_SERVICES_URL;
if (typeof absence_for_one_course !== 'string')
  throw new Error('Missing env REACT_APP_ABSENCES_FOR_ONE_COURSE_SERVICES_URL');

const absence_for_several_courses =
  process.env.REACT_APP_ABSENCES_FOR_SEVERAL_COURSES_SERVICES_URL;
if (typeof absence_for_several_courses !== 'string')
  throw new Error(
    'Missing env REACT_APP_ABSENCES_FOR_SEVERAL_COURSES_SERVICES_URL',
  );

const Environment: Env = {
  api_url,
  auth: { client_id, scopes: scopes.split(','), domain_hint },
  useMock: process.env.REACT_APP_USE_MOCK === 'true',
  mock_redirect: process.env.REACT_APP_MOCK_REDIRECT || '',
  mail_url,
  chatbot_url,
  chatbot_api_script_name,
  chat_api_url,
  chat_acs_url,
  cms_url,
  matomo_url,
  matomo_id,
  justify_absence_url,
  declare_absence_url,
  absence_for_one_course,
  absence_for_several_courses,
  google_api_key,
  environnement
};

export default Environment;
