import { Box, Button, Link as MuiLink, Stack, SxProps, Theme, Typography, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import Results, { Result } from './results/Results';
import Step1, { SEARCH_TYPES, SearchTypes } from './steps/Step1';
import Step2, { SearchCriteria, SubCriteriaValue } from './steps/Step2';
import Services from 'utilities/Services/index';
import MatomoService, { SmartSearchTypeName } from 'services/MatomoService';

import CommentsAvatar from '../../common/comments/CommentsAvatar';
import { FC, useCallback } from 'react';
import { Icon } from 'core/utils';
import { Link } from 'react-router-dom';
import { PageContainer } from '../../common';
import PageTitle from '../../common/PageTitle';
import { RemoteConfiguration } from '@ward/library';
import { Routes } from '../../../core/router';
import SearchStepper from './common/SearchStepper';
import Step3 from './steps/Step3';
import emnAlumniLogo from 'assets/search/emn-alumni-logo.png';
import searchBackground from 'assets/search/search-background.png';

const containerStyle: SxProps<Theme> = {
  paddingTop: 2,
  paddingLeft: 14,
};

const titleStyle: SxProps<Theme> = {
  fontSize: 25,
  fontWeight: 600,
  marginBottom: 4,
};

const botContainerStyle: SxProps<Theme> = {
  position: 'relative',
  width: 400,
  paddingTop: 4,
  paddingBottom: 3,
  marginTop: 10,
  backgroundColor: (theme) => theme.palette.primary.main,
  borderRadius: 4,
  textAlign: 'center',
};

const botAvatarStyle: SxProps<Theme> = {
  position: 'absolute',
  top: -22,
  left: 'calc(50% - 22px)',
};

const botNameStyle: SxProps<Theme> = {
  fontSize: 12,
  fontWeight: 700,
};

const botLabelStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  padding: '2px 3px',
  marginLeft: 1,
  fontSize: 8,
  fontWeight: 700,
  lineHeight: 1,
  borderRadius: '3px',
  color: (theme) => theme.palette.primary.main,
  background: (theme) => alpha(theme.palette.primary.contrastText, 0.8),
  svg: {
    marginRight: '2px',
    color: (theme) => alpha(theme.palette.primary.main, 0.5),
  },
};

const botTitleStyle: SxProps<Theme> = {
  fontSize: 16,
  fontWeight: 400,
  marginTop: 2,
  marginBottom: 1,
};

const botButtonStyle: SxProps<Theme> = {
  height: 34,
  paddingY: 0,
  paddingX: 3,
  fontSize: 12,
  color: (theme) => theme.palette.primary.contrastText,
  border: '1px solid',
  borderColor: (theme) => alpha(theme.palette.primary.contrastText, 0.5),
  borderRadius: '19px',
};

const alumniTextStyle: SxProps<Theme> = {
  fontSize: 14,
  fontWeight: 400,
  marginTop: 10,
  marginBottom: 4,
};

const alumniLogoStyle: SxProps<Theme> = {
  display: 'block',
  width: 150,
};

type SearchProps = {
  remoteConfiguration: RemoteConfiguration,
  currentStep: number | null,
  onClickStep: Function,
  searchType?: SearchTypes['value'],
  searchCriteria?: SearchCriteria['value'] | SubCriteriaValue,
  searchValue?: number | string,
  results: Result[] | null,
  onSelectStep1: Function,
  onSelectStep2: Function,
  onSelectStep3: Function,
  isLoadingResults: boolean,
  isSubmitSearch: boolean,
};

const Search: FC<SearchProps> = ({
  remoteConfiguration: { bot, alumnis_website_url },
  currentStep,
  onClickStep,
  searchType,
  searchCriteria,
  searchValue,
  results,
  onSelectStep1,
  onSelectStep2,
  onSelectStep3,
  isLoadingResults,
  isSubmitSearch,
}) => {
  const { t } = useTranslation<Namespace>('search');

  const currentTypeLabel = searchType
    ? SEARCH_TYPES.find((item) => item.value === searchType)?.label
    : null;

  const title = currentStep === 0
    ? t('I am looking for')
    : t('per', { label: currentTypeLabel });

  const { whoami, matomo } = Services.useAll();
  const trackSearchStaff = () => {
    matomo.trackPageEvent({
      action: 'click',
      category: 'SmartSearch',
      name: 'Staff',
      value: 1,
      uri: Routes.Chat,
    });
  };
  const trackSearchAlumni = () => {
    matomo.trackPageEvent({
      action: 'click',
      category: 'SmartSearch',
      name: 'Alumni',
      value: 1,
      uri: Routes.Chat,
    });
  };

  return (
    <>
      <PageContainer backgroundImage={searchBackground}>
        <PageTitle title={t('smart search')} iconId='feature_smartsearch' />

        <Box sx={containerStyle}>
          {typeof currentStep === 'number' &&
            <>
              <SearchStepper currentStep={currentStep} onClickStep={onClickStep} />

              <Typography variant='h2' sx={titleStyle}>
                {title}
              </Typography>

              {currentStep === 0 &&
                <Step1 selected={searchType} setSelected={onSelectStep1} />
              }

              {searchType === 'student' &&
                <>
                  {currentStep === 1 &&
                    <Step2 selected={searchCriteria} setSelected={onSelectStep2} setValue={onSelectStep3} />
                  }
                  {(currentStep === 2 && searchCriteria) &&
                    <Step3 searchCriteria={searchCriteria} selected={searchValue} setSelected={onSelectStep3} />
                  }
                </>
              }

              {searchType === 'staff' &&
                <Box sx={botContainerStyle}>
                  <Box sx={botAvatarStyle}>
                    <CommentsAvatar src={bot.image} />
                  </Box>
                  <Stack direction='row' alignItems='center' justifyContent='center'>
                    <Typography sx={botNameStyle}>
                      {bot.name}
                    </Typography>
                    <Typography sx={botLabelStyle}>
                      <Icon icon='feature_chatbot' size={8} />
                      {t('bot')}
                    </Typography>
                  </Stack>
                  <Typography sx={botTitleStyle}>
                    {t('have a question ?')}
                  </Typography>
                  <Link to={Routes.Chat} onClick={trackSearchStaff}>
                    <Button sx={botButtonStyle}>
                      {t('I ask', { name: bot.name })}
                    </Button>
                  </Link>
                </Box>
              }

              {searchType === 'alumni' &&
                <>
                  <Typography sx={alumniTextStyle}>
                    {t('login on the EMN Alumni website')}
                  </Typography>
                  <MuiLink sx={alumniLogoStyle} href={alumnis_website_url} target='_blank' onClick={trackSearchAlumni}>
                    <img src={emnAlumniLogo} alt='EMN Alumni' />
                  </MuiLink>
                </>
              }
            </>
          }
        </Box>
      </PageContainer>

      {(searchType && isSubmitSearch) &&
        <Results results={results} searchType={searchType} isLoading={isLoadingResults} />
      }
    </>
  );
};

export default Search;
