import * as React from 'react';

import type { SxProps, Theme } from '@mui/system';

import { APPBAR_HEIGHT } from './appTopBar/AppTopBar';
import { Container } from '@mui/material';

const containerStyle: SxProps<Theme> = {
  padding: 4,
  marginLeft: 0,
  marginRight: 'auto',
  minWidth: 1024,
};

const backgroundStyle: SxProps<Theme> = {
    position: 'fixed',
    zIndex: -10,
    top: 0,
    backgroundPosition: 'top center',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    minHeight: `calc(100vh - ${APPBAR_HEIGHT}px)`,
};

interface PageContainerProps {
  children: React.ReactNode;
  backgroundImage?: string,
}

const PageContainer: React.FC<PageContainerProps> = ({ children, backgroundImage }) => {
  const backgroundStyles = backgroundImage
    ? { backgroundImage: `url(${backgroundImage})`}
    : {};

  return (
    <>
      <Container maxWidth={false} sx={[backgroundStyle, backgroundStyles]} disableGutters />
      <Container maxWidth='lg' sx={containerStyle} disableGutters>
        {children}
      </Container>
    </>
  );
};

export default PageContainer;
