import { AsyncState, MenuZone } from '@ward/library';

import { FC } from 'react';
import LinksMenu from '../LinksMenu';
import Services from 'utilities/Services';
import requests from '../requests';
import { setIsExperiences } from './isExperiencesSlice';
import { useDispatch } from 'react-redux';

const Experiences: FC = () => {
  const { data } = Services.useAll();
  const dispatch = useDispatch();

  let experiences: MenuZone[] = [];
  const state = data.useResult(requests.getMenus());

  if (AsyncState.isResolved(state)) {
    experiences = state.value.experiences;
  }

  const close = () => {
    dispatch(setIsExperiences(false));
  };

  const isLoading = !experiences || experiences.length === 0;

  return <LinksMenu data={experiences} close={close} isLoading={isLoading} />;
};

export default Experiences;
