import {
  Box,
  Button,
  Grid,
  Icon,
  Stack,
  SxProps,
  Theme,
  Typography,
  alpha,
  lighten,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { APPBAR_HEIGHT } from '../../common/appTopBar/AppTopBar';
import AppLogo from 'assets/common/emn-logo.svg';
import WardIcon from 'core/utils/Icon';
import authentication from 'core/api/authentication';

const containerStyle: SxProps<Theme> = {
  height: '100vh',
};

const loginBgStyle: SxProps<Theme> = (theme) => ({
  backgroundColor: theme.palette.primary.dark,
  height: '100%',
  marginLeft: '-16px',
  alignItems: 'center',
});

const loginTitleStyle: SxProps<Theme> = (theme) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '60px 0 0 0',
  [theme.breakpoints.down('sm')]: {
    padding: '170px 0 0 0',
  },
});

const circleImgStyle: SxProps<Theme> = {
  padding: '0 25px 0 85px',
};

const iconStyle: SxProps<Theme> = {
  position: 'relative',
  '&:after': {
    content: "''",
    position: 'absolute',
    top: '0px',
    width: '1px',
    height: '55px',
    background: 'rgb(255,255,255,.34)',
    left: '0px',
    margin: '20px 0 0 127px',
  },
};

const appIconStyle: SxProps<Theme> = {
  height: APPBAR_HEIGHT,
  width: APPBAR_HEIGHT,
  backgroundColor: 'red',
  zIndex: 2,
};

const wardStyle: SxProps<Theme> = {
  h1: {
    fontWeight: 700,
    fontSize: 35,
  },
};

const loginButtonStyle: SxProps<Theme> = (theme) => ({
  border: '1px solid',
  borderColor: theme.palette.secondary.contrastText,
  width: '305px',
  padding: '14px 32px',
  borderRadius: '50px',
  fontSize: 13,
  letterSpacing: '0.08em',
  color: theme.palette.secondary.contrastText,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.contrastText, .1),
  },
});

const liRightStyle: SxProps<Theme> = (theme) => ({
  backgroundImage: 'url(../../../login/login-background.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100%',
  height: '100%',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    height: '750px',
  },
  h2: {
    fontWeight: 100,
    fontSize: 50,
    textAlign: 'left',
    color: theme.palette.primary.contrastText,
    padding: '222px 0 0 120px',
    maxWidth: '676px',
    width: '100%',
    letterSpacing: '0px',
    lineHeight: '50px',
    textTransform: 'inherit',
    [theme.breakpoints.down('md')]: {
      maxWidth: '586px',
      fontSize: 40,
    },
  },
  '&:after': {
    content: "''",
    backgroundImage: 'url(../../../login/blur-circle.png)',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    height: '250px',
    right: '33%',
  },
  '&:before': {
    content: "''",
    backgroundImage: 'url(../../../login/medium-round.png)',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '88px',
    height: '175px',
    marginTop: '256px',
    right: 0,
  },
});

const roundBgStyle: SxProps<Theme> = (theme) => ({
  position: 'relative',
  '&:after': {
    content: "''",
    backgroundImage: 'url(../../../login/big-round.png)',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '300px',
    height: '300px',
    marginTop: '444px',
    right: '20px',
  },
  '&:before': {
    content: "''",
    backgroundImage: 'url(../../../login/small-round.png)',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    width: '120px',
    height: '120px',
    marginTop: '612px',
    left: '22%',
    [theme.breakpoints.down('sm')]: {
      zIndex: 1,
    },
  },
});

const liProcessStyle: SxProps<Theme> = {
  marginBottom: '40px',
};

const securityButtonStyle: SxProps<Theme> = (theme) => ({
  background: theme.palette.primary.main,
  padding: '16px 41px',
  borderRadius: '30px',
  width: '230px',
  color: theme.palette.secondary.contrastText,
  fontSize: 12,
  fontWeight: 500,
  '&:hover': {
    background: lighten(theme.palette.primary.main, .05),
  },
});

const liButtonCheckedStyle: SxProps<Theme> = {
  background: 'linear-gradient(345deg, rgba(200,209,255,1) 0%, rgba(247,249,255,1) 100%)',
  padding: '10px 41px',
  borderRadius: '30px',
  width: '207px',
  margin: '0 auto',
  'button, a': {
    color: theme => theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 500,
  },
};

const licheckedStyle: SxProps<Theme> = (theme) => ({
  marginBottom: '108px',
  [theme.breakpoints.down('sm')]: {
    marginBottom: '70px',
  },
});

const commonButtonStyle: SxProps<Theme> = {
  position: 'relative',
  zIndex: 1,
};

const bodyStyle: SxProps<Theme> = {
  margin: '1em',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const Login = (): JSX.Element => {
  const token = localStorage.getItem('token');
  const [section, setSection] = useState(!token ? 1 : 3);
  const handleOnClickStepOne = () => {
    setSection(2);
  };

  const [link, setLink] = useState<string | null>(null);

  useEffect(() => {
    authentication?.buildSingleSignOnWebPageUrl().then(setLink);
  }, []);

  const handleOnClickStepThree = () => {
    window.location.href = '/';
  };

  const authenticated: boolean = !token ? false : true;

  return (
    <Grid container sx={containerStyle}>
      {!authenticated && (
        <>
          <Grid item xs={12} sm={4}>
            <Stack direction='column' sx={loginBgStyle}>
              <Stack direction='row' sx={loginTitleStyle} marginBottom="108px">
                <Box sx={iconStyle}>
                  <Icon sx={appIconStyle}>
                    <img src={AppLogo} alt="EMN Logo" />
                  </Icon>
                </Box>
                <Box sx={circleImgStyle}>
                  <WardIcon icon='feature_menu_outline' size={54} />
                </Box>
                <Box sx={wardStyle}>
                  <Typography variant="h1">
                    WARD                
                    { process.env.REACT_APP_ENVIRONNEMENT !== "production" &&
                    <Typography variant="h1" color="red">{process.env.REACT_APP_ENVIRONNEMENT}</Typography>
                    }
                  </Typography>
                </Box>
              </Stack>
              {section === 1 &&
                <Button
                  disableRipple
                  disableFocusRipple
                  onClick={handleOnClickStepOne}
                  sx={[commonButtonStyle, loginButtonStyle]}
                >
                  Étudiant EM Normandie
                </Button>
              }
              {section === 2 &&
                <>
                  <Grid sx={liProcessStyle}>
                    <img src="../../../login/icon-security-check.png" alt="" />
                  </Grid>
                  {link
                    ? <Button
                      disableRipple
                      disableFocusRipple
                      href={link}
                      sx={[commonButtonStyle, securityButtonStyle]}
                    >
                      Security check
                    </Button>
                    : null
                  }
                </>
              }
              <Typography sx={bodyStyle}>
                Cliquez sur le bouton &apos;Security Check&apos; pour vous authentifier
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box sx={liRightStyle}>
              <Box sx={roundBgStyle}></Box>
              <Typography variant="h2">
                Bienvenue dans Ward La plateforme étudiante officielle de l’EM
                Normandie
              </Typography>
            </Box>
          </Grid>{' '}
        </>
      )}

      {/* login checked */}
      {authenticated && section === 3 && (
        <>
          <Grid item xs={12} sm={4}>
            <Grid sx={loginBgStyle}>
              <Grid sx={{ ...loginTitleStyle, ...licheckedStyle }}>
                <Grid sx={iconStyle}>
                  <Icon sx={appIconStyle}>
                    <img src={AppLogo} alt="EMN Logo" />
                  </Icon>
                </Grid>
                <Grid sx={circleImgStyle}>
                  <WardIcon icon='feature_menu_outline' size={54} />
                </Grid>
                <Grid sx={wardStyle}>
                  <Typography variant="h1" >
                    WARD
                  </Typography>
                </Grid>
              </Grid>
              <Grid sx={liProcessStyle}>
                <img src="../../../login/icon-go.png" alt="" />
              </Grid>
              <Grid sx={liButtonCheckedStyle}>
                <Button onClick={handleOnClickStepThree}>Go to Ward !</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Grid sx={liRightStyle}>
              <Grid sx={roundBgStyle}></Grid>
              <Typography variant="h2">
                Bienvenue dans Ward La plateforme étudiante officielle de l’EM
                Normandie
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {/* login checked end */}
    </Grid>
  );
};
export default Login;
