import { FC, useState } from 'react';
import { IdOf, Model } from '@ward/library';
import { SearchCriteria, SubCriteriaValue } from './steps/Step2';

import { Result } from './results/Results';
import Search from './Search';
import { SearchTypes } from './steps/Step1';
import Services from 'utilities/Services';
import { SmartSearchQueryPayload } from '@ward/library/dist/Endpoints/students';
import requests from './requests';
import WhoAmIService from 'library/WhoAmIService';
import MatomoService, { SmartSearchTypeName } from 'services/MatomoService';

function trackSearch(criteria: SearchCriteria['value'] | SubCriteriaValue | 'teacher', whoami: WhoAmIService) {
  // const matomo = new MatomoService(whoami);

  // if (criteria === 'myClass') matomo.smartSearchEvent(SmartSearchTypeName.StudentMyPromo);
  // if (criteria === 'class') matomo.smartSearchEvent(SmartSearchTypeName.StudentOtherPromo);
  // if (criteria === 'hobby') matomo.smartSearchEvent(SmartSearchTypeName.StudentInterests);
  // if (criteria === 'topic') matomo.smartSearchEvent(SmartSearchTypeName.StudentProjectTopic);
  // if (criteria === 'assistance') matomo.smartSearchEvent(SmartSearchTypeName.StudentTopSkills);
  // if (criteria === 'association') matomo.smartSearchEvent(SmartSearchTypeName.StudentProjectAssos);
  // if (criteria === 'classes') matomo.smartSearchEvent(SmartSearchTypeName.StudentOtherPromo);
  // if (criteria === 'project') matomo.smartSearchEvent(SmartSearchTypeName.StudentProjectTopic);
  // if (criteria === 'countryCode') matomo.smartSearchEvent(SmartSearchTypeName.StudentCountry);
  // if (criteria === 'companySiret') matomo.smartSearchEvent(SmartSearchTypeName.StudentProfessionalXP);
  // if (criteria === 'teacher') matomo.smartSearchEvent(SmartSearchTypeName.Teacher);
}

const SearchContainer: FC = () => {
  const { data, remoteConfiguration, whoami } = Services.useAll();
  const config = remoteConfiguration.getConfiguration();
  const [currentStep, setCurrentStep] = useState<number | null>(0);
  const [searchType, setSearchType] = useState<SearchTypes['value']>();
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria['value'] | SubCriteriaValue>();
  const [searchValue, setSearchValue] = useState<number | string>();
  const [results, setResults] = useState<Result[] | null>(null);
  const [isLoadingResults, setIsLoadingResults] = useState<boolean>(false);
  const [isSubmitSearch, setIsSubmitSearch] = useState<boolean>(false);

  const onClickStep = (value: number | null) => {
    setSearchValue(undefined);
    setIsSubmitSearch(false);
    setCurrentStep(value);
    if (value === 0) {
      setSearchType(undefined);
    }
  };

  const submitSearchStudent = async (
    criteria?: SearchCriteria['value'] | SubCriteriaValue,
    value?: number | string
  ) => {
    if (!criteria || !value) return;
    setIsLoadingResults(true);
    setIsSubmitSearch(true);

    const smartsearch: SmartSearchQueryPayload = {
      class: criteria === 'classes' || criteria === 'myClass'
        ? value as IdOf<Model.Class> | 'mine'
        : undefined,
      topic: criteria === 'topic' ? value as IdOf<Model.Topic> : undefined,
      countryCode: criteria === 'countryCode' ? value as Model.Country['code'] : undefined,
      hobby: criteria === 'hobby' ? value as IdOf<Model.Hobby> : undefined,
      companySiret: criteria === 'companySiret' ? value as Model.Company['siret'] : undefined,
      association: criteria === 'association' ? value as IdOf<Model.Association> : undefined,
      assistance: criteria === 'assistance' ? value as IdOf<Model.Assistance> : undefined,
    };

    trackSearch(criteria, whoami);

    const response = await data.fetch(requests.searchStudents(smartsearch));
    setIsLoadingResults(false);
    if (!response) return;
    setResults(response);
  };

  const submitSearchTeacher = async () => {
    setIsLoadingResults(true);
    setIsSubmitSearch(true);
    trackSearch('teacher', whoami);
    const response = await data.fetch(requests.getMyTeachers());
    setIsLoadingResults(false);
    if (!response) return;
    const mappedData: Result[] = response.map((item: any) => ({
      ...item,
      firstname: item.first_name,
      lastname: item.last_name,
      photo: item.image,
    }));
    setResults(mappedData);
  };

  const onSelectStep1 = (value: SearchTypes['value']) => {
    setSearchType(value);
    setResults(null);
    setIsSubmitSearch(false);
    if (value === 'student' && typeof currentStep === 'number') {
      setCurrentStep(currentStep + 1);
    } else if (value === 'teacher') {
      submitSearchTeacher();
    } else {
      setCurrentStep(1);
    }
  };

  const onSelectStep2 = (criteria: SearchCriteria['value'] | SubCriteriaValue) => {
    setSearchCriteria(criteria);
    setResults(null);
    setIsSubmitSearch(false);
    if (typeof currentStep === 'number' && criteria !== 'myClass') {
      setCurrentStep(currentStep + 1);
    } else if (criteria === 'myClass') {
      submitSearchStudent(criteria, 'mine');
    }
  };

  const onSelectStep3 = (value: number | string) => {
    setSearchValue(value);
    setResults(null);
    setIsSubmitSearch(false);
    if (searchType === 'student') {
      submitSearchStudent(searchCriteria, value);
    }
  }

  if (!config) {
    return null;
  }

  return (
    <Search
      searchValue={searchValue}
      results={results}
      currentStep={currentStep}
      onClickStep={onClickStep}
      searchType={searchType}
      searchCriteria={searchCriteria}
      remoteConfiguration={config}
      onSelectStep1={onSelectStep1}
      onSelectStep2={onSelectStep2}
      onSelectStep3={onSelectStep3}
      isLoadingResults={isLoadingResults}
      isSubmitSearch={isSubmitSearch}
    />
  );
};

export default SearchContainer;
