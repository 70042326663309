import { Stack, SxProps, Theme, Typography, useTheme } from '@mui/material';

import { FC } from 'react';
//@ts-ignore
// import Spinner from 'react-spinkit';
import './LoadingView.css';
import { useTranslation } from 'react-i18next';

const containerStyle: SxProps<Theme> = {
    justifyContent: 'center',
    alignItems: 'center',

    '& .sk-spinner': {
      opacity: .6,
    },
    '& .sk-chasing-dots': {
      width: 40,
      height: 40,
    },
};
  
const textStyle: SxProps<Theme> = {
    marginTop: 2.5,
    fontSize: 10,
  };

type LoadingViewProps = {
  text?: string | null;
  color?: string;
  height?: number | string;
};

const LoadingView: FC<LoadingViewProps> = ({ text, color, height = 300 }) => {
  const { t } = useTranslation('common');
  const themeVariable = useTheme();
  const content = text === undefined ? t('loading') : text;

  return (
    <Stack sx={[
      containerStyle,
      { '& .sk-spinner': { color: color || themeVariable.palette.primary.contrastText }},
      { height },
    ]}>
      {/* <div className="hypnotic"></div> */}
      <div className="spinner"></div>
      {/* <Spinner
        name="chasing-dots"
        color={color}
      /> */}
      <Typography sx={textStyle} variant='h3'>
        {content}
      </Typography>
    </Stack>
  );
}

export default LoadingView;
