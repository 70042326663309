import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from 'i18next';
import { SxProps, Theme, alpha } from '@mui/system';

import { AbuseReasonsContentType } from '@ward/library/dist/Endpoints/abuse-reasons';
import { Icon } from '../../../core/utils';
import { Model } from '@ward/library';
import Services from 'utilities/Services';
import requests from '../requests';

const openModalButtonStyle: SxProps<Theme> = {
  color: theme => alpha(theme.palette.primary.contrastText, .6),
  textTransform: 'initial',
  marginTop: '34px !important',
  cursor: 'pointer',
  borderRadius: '25px',
  border: '1px solid',
  borderColor: theme => alpha(theme.palette.primary.contrastText, .4),
  paddingY: 1 / 2,
  paddingX: 3,
  position: 'relative',
  fontSize: 12,
  fontWeight: 300,
  margin: ' 0 auto',
  '&:hover': {
    color: theme => alpha(theme.palette.primary.contrastText, .8),
    borderColor: theme => alpha(theme.palette.primary.contrastText, .6),
  },
  '& .MuiButton-startIcon': {
    color: theme => alpha(theme.palette.primary.contrastText, .4),
  },
};

const openModalIconButtonStyle: SxProps<Theme> = {
  padding: 0,
  minWidth: 'unset',
  color: theme => alpha(theme.palette.primary.contrastText, .6),
};

const modalReportStyle: SxProps<Theme> = {
  position: 'absolute',
  marginTop: '21%',
  left: '45%',
  transform: 'translate(-50%, -50%)',
  bgcolor: theme => theme.palette.primary.dark,
  borderRadius: '20px',
  boxShadow: 24,
  maxWidth: '568px',
  width: '100%',
  textAlign: 'center',
};

const modalReportIconStyle: SxProps<Theme> = {
  position: 'absolute',
  top: -20,
  left: 'calc(50% - 20px)',
  color: theme => theme.palette.secondary.main,
};

const modalReportContentStyle: SxProps<Theme> = {
  marginTop: 5,
};

const modalReportTitleStyle: SxProps<Theme> = {
  fontSize: 18,
  fontWeight: 600,
  color: theme => theme.palette.primary.contrastText,
  letterSpacing: '0px',
  marginBottom: '43px',
  padding: ' 0px 135px',
  textAlign: 'center',
};

const modalReportValidateStyle: SxProps<Theme> = {
  margin: '0 0 0 6px',
  fontWeight: 800,
  width: '210px',
  marginBottom: '50px',
  '&.Mui-disabled': {
    background: theme => alpha(theme.palette.primary.contrastText, .3),
    color: theme => alpha(theme.palette.primary.contrastText, .3),
  },
};

const modalReportButtonsStyle: SxProps<Theme> = {
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: '55px',
  marginBottom: '30px',
};

const modalReportButtonsItemStyle: SxProps<Theme> = {
  marginBottom: '10px',
  border: '1px solid',
  color: theme => theme.palette.primary.contrastText,
  borderColor: theme => theme.palette.primary.contrastText,
  borderRadius: '20px',
  width: '210px',
  fontSize: 10,
  fontWeight: 500,
  textAlign: 'center',
  marginLeft: '11px',
};

const modalReportButtonsItemSelectedStyle: SxProps<Theme> = {
  background: theme => theme.palette.primary.contrastText,
  color: theme => theme.palette.primary.main,
  '&:hover': {
    background: theme => theme.palette.primary.contrastText,
  },
};

type ModalReportProps = {
  submitReport: Function,
  contentType: AbuseReasonsContentType,
  iconOnly?: boolean,
}

const ModalReport: FC<ModalReportProps> = ({ submitReport, contentType, iconOnly }) => {
  const { t } = useTranslation<Namespace>('common');
  const { data } = Services.useAll();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [reasons, setReasons] = useState<Model.AbuseReason[]>([]);
  const [selectedReason, setSelectedReason] = useState<string>('');
  const toggleOpenModal = () => setOpenModal(!openModal);

  const getReasons = useCallback(async () => {
    const response = await data.fetch(requests.getAbuseReasons(contentType));
    if (!response) return;
    setReasons(response);
  }, [contentType, data]);

  useEffect(() => {
    getReasons();
  }, [getReasons]);

  const handleSubmitReport = () => {
    submitReport(selectedReason);
    setSelectedReason('');
    toggleOpenModal();
  };

  return (
    <>
      {iconOnly
        ? (
          <Button
            disableRipple
            disableFocusRipple
            sx={openModalIconButtonStyle}
            onClick={toggleOpenModal}
          >
            <Icon icon='navigation_exclamation-circle' size={20} />
          </Button>
        ) : (
          <Button
            disableRipple
            disableFocusRipple
            sx={openModalButtonStyle}
            onClick={toggleOpenModal}
            startIcon={
              <Icon icon='navigation_exclamation-circle' size={20} />
            }
          >
            {t('report abuse')}
          </Button>
        )}

      <Modal
        open={openModal}
        onClose={toggleOpenModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalReportStyle}>
          <Grid sx={modalReportIconStyle}>
            <Icon icon='navigation_exclamation-circle' size={40} />
          </Grid>
          <Grid sx={modalReportContentStyle}>
            <Typography id="modal-modal-title" variant="h2" component="h2" sx={modalReportTitleStyle}>
              {t('report abuse')}
            </Typography>
          </Grid>
          <Grid sx={modalReportButtonsStyle}>
            {reasons.map((reason: any) =>
              <Button
                key={reason.id}
                sx={[
                  modalReportButtonsItemStyle,
                  selectedReason === reason.id ? modalReportButtonsItemSelectedStyle : {},
                ]}
                disableRipple
                disableFocusRipple
                onClick={() => setSelectedReason(reason.id)}
              >
                {reason.label}
              </Button>
            )}
          </Grid>
          <Button
            sx={modalReportValidateStyle}
            disableRipple
            disableFocusRipple
            type="submit"
            variant="contained"
            size="small"
            onClick={handleSubmitReport}
            disabled={selectedReason.length === 0}
          >
            {t('send abuse')}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ModalReport;
