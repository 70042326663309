import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Slider,
  Stack,
  TextField,
  Typography,
  alpha,
} from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { SearchCriteria, SubCriteriaValue } from './Step2';
import { SxProps, Theme } from '@mui/system';

import { ApiRequest } from '@ward/library';
import { Icon } from '../../../../core/utils';
import LoadingView from 'components/common/LoadingView';
import { Search as SearchIcon } from '@mui/icons-material';
import Services from 'utilities/Services';
import requests from '../requests';
import studentRequests from '../../student/requests';
import { useTranslation } from 'react-i18next';

const CHOICE_WIDTH = 201;
const NB_DISPLAY = 5;

const containerStyle: SxProps<Theme> = {
  maxWidth: `calc((${CHOICE_WIDTH}px * ${NB_DISPLAY}) + (16px * ${NB_DISPLAY - 1}))`,
};

const choicesContainerStyle: SxProps<Theme> = {
  position: 'relative',
  overflow: 'hidden',
  maxWidth: '100%',
  marginBottom: 3,
};

const choicesStyle: SxProps<Theme> = {
  position: 'relative',
  left: 0,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  transition: 'all 300ms',
};

const choiceStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  flex: `0 0 ${CHOICE_WIDTH}px`,
  height: 187,
  marginRight: 2,
  alignItems: 'center',
  justifyContent: 'center',
  color: (theme) => theme.palette.primary.contrastText,
  border: '1px solid',
  borderColor: (theme) => theme.palette.primary.contrastText,
  borderRadius: 2,
  textTransform: 'none',
  opacity: 0.2,
  '&:last-child': {
    marginRight: 0,
  },
  '&:hover': {
    background: '#6678D8',
    borderColor: '#6678D8',
    opacity: 1,
  },
  img: {
    width: 70,
    color: (theme) => alpha(theme.palette.primary.contrastText, 0.13),
  },
};

const choiceNearStyle: SxProps<Theme> = {
  opacity: 0.59,
};

const choiceCurrentStyle: SxProps<Theme> = {
  opacity: 1,
  background: '#05156B',
  borderColor: '#6678D8',
};

const choiceSelectedStyle: SxProps<Theme> = {
  opacity: 1,
  background: '#6678D8',
};

const choiceLabelStyle: SxProps<Theme> = {
  fontSize: 14,
  fontWeight: 600,
};

const navButtonStyle: SxProps<Theme> = {
  position: 'absolute',
  top: 'calc(50% - 23px)',
  height: 46,
  width: 46,
  color: (theme) => theme.palette.primary.main,
  backgroundColor: (theme) => theme.palette.primary.contrastText,
  padding: 1 / 2,
  '&:hover': {
    backgroundColor: (theme) => theme.palette.primary.contrastText,
  },
  '&.Mui-disabled': {
    backgroundColor: (theme) => alpha(theme.palette.primary.contrastText, 0.5),
  },
};

const navButtonLeftStyle: SxProps<Theme> = {
  left: `calc(${CHOICE_WIDTH}px - 46px - 12px)`,
};

const navButtonRightStyle: SxProps<Theme> = {
  right: `calc(${CHOICE_WIDTH}px - 46px - 12px)`,
};

const sliderContainerStyle: SxProps<Theme> = {
  maxWidth: 300,
  margin: '0 auto',
  '& .MuiSlider-thumbColorPrimary': {
    border: '4px solid',
    borderColor: (theme) => theme.palette.primary.contrastText,
  },
  '& .MuiSlider-rail': {
    height: '1px',
    opacity: 1,
    color: (theme) => alpha(theme.palette.primary.contrastText, 0.6),
  },
  '& .MuiSlider-track': {
    display: 'none',
  },
};

const searchStyle: SxProps<Theme> = {
  width: 'calc(33.33% - 10px)',
  '& .MuiInputBase-input': {
    height: 48,
    paddingY: '0 !important',
    paddingLeft: '8px !important',
    color: (theme) => theme.palette.primary.contrastText,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '24px',
    borderColor: (theme) => theme.palette.primary.contrastText,
  },
  '& .MuiInputBase-root': {
    padding: 0,
    fontSize: 14,
    fontWeight: 600,
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: (theme) =>
        `${theme.palette.primary.contrastText} !important`,
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: (theme) =>
        `${theme.palette.primary.contrastText} !important`,
    },
  },
  '& .MuiInputAdornment-root svg': {
    color: (theme) => theme.palette.primary.contrastText,
  },
  '& .MuiInputAdornment-positionStart .MuiSvgIcon-root': {
    marginLeft: 2,
    width: 22,
  },
};

const companyStyle: SxProps<Theme> = {
  minHeight: 48,
  height: '100%',
  paddingX: 2.5,
  alignItems: 'center',
  justifyContent: 'center',
  color: (theme) => theme.palette.primary.contrastText,
  background: '#05156B',
  border: '1px solid',
  borderColor: '#6678D8',
  borderRadius: '24px',
  textTransform: 'none',
  lineHeight: 1,
  '&:hover': {
    background: '#6678D8',
  },
};

const REQUESTS = {
  class: null,
  myClass: null,
  classes: requests.getClasses(),
  countryCode: requests.getCountries(),
  companySiret: studentRequests.getCompanies(),
  project: null,
  topic: studentRequests.getTopics(),
  association: studentRequests.getAssociations(),
  hobby: studentRequests.getHobbies(),
  assistance: studentRequests.getAssistances(),
};

type Choice = {
  id: number | string;
  label: string;
};

export type Step3Props = {
  selected?: number | string;
  setSelected: Function;
  searchCriteria: SearchCriteria['value'] | SubCriteriaValue;
};

const Step3: FC<Step3Props> = ({ searchCriteria, selected, setSelected }) => {
  const { t } = useTranslation('search');
  const { data } = Services.useAll();
  const [choices, setChoices] = useState<Choice[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const mapData = useCallback(
    (response: any[]) => {
      switch (searchCriteria) {
        case 'countryCode':
          return response.map(item => ({
            id: item.code,
            label: item.label,
          }));
        case 'companySiret':
          return response.map(item => ({
            id: item.id,
            label: item.label,
          }));
        default:
          return response;
      }
    },
    [searchCriteria],
  );

  const fetchChoices = useCallback(
    async (request: ApiRequest<any> | null) => {
      if (!request) {
        return;
      }
      setIsLoading(true);
      const response = await data.fetch(request);
      setIsLoading(false);
      if (!response) return;
      const mappedData = mapData(response);
      setChoices(mappedData);
    },
    [data, mapData],
  );

  useEffect(() => {
    if (searchCriteria && searchCriteria !== 'companySiret') {
      fetchChoices(REQUESTS[searchCriteria]);
    }
  }, [fetchChoices, searchCriteria]);

  const onSlide = (value: number | number[]) => {
    const newCurrent = typeof value === 'object' ? value[0] : value;
    setCurrentIndex(newCurrent);
  };

  const navigate = (direction: number) => {
    const newCurrentIndex = currentIndex + direction;
    if (newCurrentIndex < 0 || newCurrentIndex > choices.length - 1) return;
    setCurrentIndex(newCurrentIndex);
  };

  const handleClick = (id: number | string, index: number) => {
    setSelected(id);
    setCurrentIndex(index);
  };

  const searchCompanies = useCallback(
    (value: string) => {
      fetchChoices(studentRequests.getCompanies(value));
    },
    [fetchChoices],
  );

  useEffect(() => {
    let debouncedSearch: any;
    if (search.length > 2) {
      debouncedSearch = setTimeout(() => searchCompanies(search), 500);
    } else {
      setChoices([]);
    }

    return () => clearTimeout(debouncedSearch);
  }, [search, searchCompanies]);

  const initialItem = Math.floor(NB_DISPLAY / 2);
  const initialLeft = initialItem * CHOICE_WIDTH + initialItem * 16;
  const sliderLeft = -currentIndex * (CHOICE_WIDTH + 16) + initialLeft;

  return (
    <Box sx={containerStyle}>
      {searchCriteria === 'companySiret' ? (
        <Box >
          <TextField
            sx={searchStyle}
            value={search}
            onChange={event => setSearch(event.target.value)}
            placeholder={t('company name')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {search.length > 0 && (
                    <IconButton onClick={() => setSearch('')}>
                      <Icon icon="navigation_close" size={22} />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          {isLoading ? (
            <LoadingView text={t('search in progress')} />
          ) : (
            <Grid container spacing={1.5}>
              {choices.map((choice: any, index: number) => (
                <Grid key={index} item xs={6} md={4}>
                  <Button
                    key={choice.id}
                    disableRipple
                    disableFocusRipple
                    onClick={() => handleClick(choice.id, index)}
                    sx={[
                      companyStyle,
                      selected === choice.id ? choiceSelectedStyle : {},
                    ]}
                    fullWidth
                  >
                    <Typography sx={choiceLabelStyle}>
                      {choice.label}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      ) : (
        <>
          {isLoading ? (
            <LoadingView text={t('search in progress')} />
          ) : (
            <>
              <Box sx={choicesContainerStyle}>
                <Stack sx={[choicesStyle, { left: sliderLeft }]}>
                  {choices.map((choice: any, index: number) => (
                    <Button
                      key={choice.id}
                      disableRipple
                      disableFocusRipple
                      onClick={() => handleClick(choice.id, index)}
                      sx={[
                        choiceStyle,
                        currentIndex === index ? choiceCurrentStyle : {},
                        currentIndex === index - 1 || currentIndex === index + 1
                          ? choiceNearStyle
                          : {},
                        selected === choice.id ? choiceSelectedStyle : {},
                      ]}
                    >
                      <Typography sx={choiceLabelStyle}>
                        {choice.label}
                      </Typography>
                    </Button>
                  ))}
                </Stack>

                <IconButton
                  onClick={() => navigate(-1)}
                  sx={[navButtonStyle, navButtonLeftStyle]}
                  disabled={currentIndex === 0}
                >
                  <Icon icon="navigation_chevron-left" size={32} />
                </IconButton>

                <IconButton
                  onClick={() => navigate(1)}
                  sx={[navButtonStyle, navButtonRightStyle]}
                  disabled={currentIndex === choices.length - 1}
                >
                  <Icon icon="navigation_chevron-right" size={32} />
                    </IconButton>
              </Box>

              <Box sx={sliderContainerStyle}>
                    <Slider
                  value={currentIndex}
                  defaultValue={currentIndex}
                  step={1}
                  min={0}
                  max={choices.length - 1}
                  onChange={(_event, value) => onSlide(value)}
                />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default Step3;
